import React from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { T__Buildings_Title } from "../../../constants/buildings";
import {
  selectCondoBuilding,
  updateCondoBuilding,
} from "../../../store/condominiumsSlice";
import { useDispatch, useSelector } from "react-redux";
// @ts-ignore
import S from "./BuildingChanger.module.sass";

const BuildingChanger = () => {
  const currentBuilding = useSelector(selectCondoBuilding);
  const dispatch = useDispatch();
  const buttonVariant = (b: T__Buildings_Title) => {
    return b === currentBuilding ? "contained" : "outlined";
  };
  const changeBuilding = (building: T__Buildings_Title) => {
    dispatch(updateCondoBuilding({ condoBuilding: building }));
  };

  const style = {
    fontSize: `20px`,
  };
  return (
    <div className={S.SwitchButtons}>
      <ButtonGroup size="large" variant="text">
        {/*<Button*/}
        {/*  disableElevation={true}*/}
        {/*  sx={style}*/}
        {/*  onClick={() => changeBuilding("Building 3")}*/}
        {/*  variant={buttonVariant("Building 3")}*/}
        {/*>*/}
        {/*  Building 3*/}
        {/*</Button>*/}
        {/*<Button*/}
        {/*  disableElevation={true}*/}
        {/*  sx={style}*/}
        {/*  onClick={() => changeBuilding("Building 5")}*/}
        {/*  variant={buttonVariant("Building 5")}*/}
        {/*>*/}
        {/*  Building 5*/}
        {/*</Button>*/}
        <Button
          disableElevation={true}
          sx={style}
          onClick={() => changeBuilding("Building 6")}
          variant={buttonVariant("Building 6")}
        >
          Building 6
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default BuildingChanger;
