import _ from "lodash";
import S from "../SVG.module.sass";

import BUILDINGS, { I__Buildings } from "../../../../constants/buildings";
import { T__Plan_Name } from "../../../../constants/floorplans/interface";

type Props = {
  unitClass: (build: I__Buildings | undefined, unit: string) => string;
  click: (planName: T__Plan_Name) => void;
};

const Building2 = ({ unitClass }: Props) => {
  const b = _.find(BUILDINGS, { title: "Building 2" });
  return (
    <g id="BUILDING-2">
      <g className={unitClass(b, "7")} id="UNIT-7-A1">
        <polygon
          className={S.Hot}
          points="4156.652,632.354 4156.652,624.38 4115.184,624.152 4115.184,766.215 4206.665,766.215
				4206.665,632.354 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4153.0645 689.6475)"
            className={S.Number}
          >
            7
          </text>
          <text
            transform="matrix(1 0 0 1 4146.0566 719.0265)"
            className={S.Plan}
          >
            A1
          </text>
        </g>
      </g>
      <g className={unitClass(b, "8")} id="UNIT-8-Aa">
        <polygon
          className={S.Hot}
          points="4247.108,632.354 4247.108,622.481 4206.665,622.481 4206.665,632.354 4206.665,766.215
				4206.665,768.608 4297.431,768.608 4296.431,766.215 4297.431,766.215 4297.431,632.354 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4243.8584 689.6475)"
            className={S.Number}
          >
            8
          </text>
          <text
            transform="matrix(1 0 0 1 4234.2495 719.0266)"
            className={S.Plan}
          >
            Aa
          </text>
        </g>
      </g>
      <g className={unitClass(b, "9")} id="UNIT-9-A">
        <polygon
          className={S.Hot}
          points="4338.019,631.899 4338.019,622.481 4297.431,622.481 4297.431,632.354 4297.431,766.215
				4387.829,766.215 4387.829,631.899 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4334.3413 689.6473)"
            className={S.Number}
          >
            9
          </text>
          <text
            transform="matrix(1 0 0 1 4332.5986 719.0264)"
            className={S.Plan}
          >
            A
          </text>
        </g>
      </g>
      <g className={unitClass(b, "10")} id="UNIT-10-A5">
        <polygon
          className={S.Hot}
          points="4429.614,631.443 4429.614,622.481 4387.829,622.481 4387.829,631.899 4387.829,766.215
				4480.335,766.215 4481.335,631.443 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4420.0234 689.6474)"
            className={S.Number}
          >
            10
          </text>
          <text
            transform="matrix(1 0 0 1 4416.356 719.0264)"
            className={S.Plan}
          >
            A5
          </text>
        </g>
      </g>
    </g>
  );
};

export default Building2;
