import Units from "./Units";
import Outline from "./Outline";
import S from "./SVG.module.sass";
import OtherBuildings from "./OtherBuildings";

const SVG = () => {
  return (
    <svg className={S.Siteplan} viewBox="0 0 6467 3146">
      <Units />
      <Outline />
      <OtherBuildings />
    </svg>
  );
};

export default SVG;
