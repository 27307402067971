import _ from "lodash";
import S from "../SVG.module.sass";

import BUILDINGS, { I__Buildings } from "../../../../constants/buildings";
import { T__Plan_Name } from "../../../../constants/floorplans/interface";

type Props = {
  unitClass: (build: I__Buildings | undefined, unit: string) => string;
  click: (planName: T__Plan_Name) => void;
};

const Building18 = ({ unitClass }: Props) => {
  const b = _.find(BUILDINGS, { title: "Building 18" });
  return (
    <g id="BUILDING-18">
      <g className={unitClass(b, "103")} id="UNIT-103-B2">
        <polygon
          className={S.Hot}
          points="4368.17,1777.8 4461.04,1777.8 4529.42,1777.8 4534.97,1777.8 4534.97,1717.98 4529.42,1717.98
				4529.42,1686.38 4368.17,1686.38 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4426.0723 1726.0232)"
            className={S.Number}
          >
            103
          </text>
          <text
            transform="matrix(1 0 0 1 4431.4917 1755.4021)"
            className={S.Plan}
          >
            B2
          </text>
        </g>
      </g>
      <g className={unitClass(b, "104")} id="UNIT-104-B">
        <polygon
          className={S.Hot}
          points="4368.17,1686.38 4529.42,1686.38 4534.97,1686.38 4534.97,1626.32 4529.42,1626.32 4529.42,1594.98
				4368.17,1594.98 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4425.2402 1635.584)"
            className={S.Number}
          >
            104
          </text>
          <text
            transform="matrix(1 0 0 1 4439.2788 1664.9631)"
            className={S.Plan}
          >
            B
          </text>
        </g>
      </g>
      <g className={unitClass(b, "105")} id="UNIT-105-B">
        <polygon
          className={S.Hot}
          points="4368.17,1594.98 4529.42,1594.98 4534.97,1594.98 4534.97,1535.92 4529.42,1535.92 4529.42,1505.59
				4368.17,1505.59 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4426.0068 1545.145)"
            className={S.Number}
          >
            105
          </text>
          <text
            transform="matrix(1 0 0 1 4439.2793 1574.5239)"
            className={S.Plan}
          >
            B
          </text>
        </g>
      </g>
      <g className={unitClass(b, "106")} id="UNIT-106-B">
        <polygon
          className={S.Hot}
          points="4368.17,1413.95 4368.17,1505.59 4529.42,1505.59 4534.97,1505.59 4534.97,1443.52 4529.42,1443.52
				4529.42,1413.95 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4425.5776 1454.7061)"
            className={S.Number}
          >
            106
          </text>
          <text
            transform="matrix(1 0 0 1 4439.2783 1484.085)"
            className={S.Plan}
          >
            B
          </text>
        </g>
      </g>
      <g className={unitClass(b, "107")} id="UNIT-107-B1">
        <polygon
          className={S.Hot}
          points="4534.97,1413.95 4534.97,1353.88 4529.42,1353.88 4529.42,1321.5 4368.17,1321.5 4368.17,1413.95
				4529.42,1413.95 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4426.1895 1364.2668)"
            className={S.Number}
          >
            107
          </text>
          <text
            transform="matrix(1 0 0 1 4434.6245 1393.646)"
            className={S.Plan}
          >
            B1
          </text>
        </g>
      </g>
    </g>
  );
};

export default Building18;
