import { useNavigate } from "react-router-dom";
import s from "../SVG.module.sass";
import R from "../../../../constants/routes";
import { T__Buildings_Title } from "../../../../constants/buildings";
import { updateCondoBuilding } from "../../../../store/condominiumsSlice";
import { useDispatch } from "react-redux";

const OtherBuildings = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const click = (pathname: string, condoBuilding: T__Buildings_Title) => {
    dispatch(updateCondoBuilding({ condoBuilding }));
    navigation(pathname);
  };
  return (
    <g id="OTHER_BUILDINGS" className={s.OtherBuildings}>
      <g id="building-5">
        <polygon
          onClick={() =>
            click(R["CONDOMINIUM-KEY-PLAN"].pathname, "Building 5")
          }
          fill="#ffffff"
          stroke="#000000"
          strokeWidth={6}
          points="2108.142 1356.607 2108.142 1450.533 2099.756 1450.533 2099.756 1536.633 2129.387 1536.633 2129.387 1546.137 2108.142 1546.137 2108.142 1632.236 2062.856 1632.236 2062.856 1658.514 1965.016 1658.514 1965.016 1652.923 1873.326 1652.923 1873.326 1636.709 1826.922 1636.709 1826.922 1623.291 1782.195 1623.291 1782.195 1656.277 1670.377 1656.277 1670.377 1638.386 1578.687 1638.386 1578.687 1654.6 1487.556 1654.6 1487.556 1678.082 1480.288 1678.082 1480.288 1658.514 1389.716 1658.514 1389.716 1633.355 1345.548 1633.355 1345.548 1546.696 1322.625 1546.696 1322.625 1537.751 1353.375 1537.751 1353.375 1451.092 1346.107 1451.092 1346.107 1357.725 1388.038 1357.725 1388.038 1331.772 1488.115 1331.772 1488.115 1345.425 1525.015 1345.425 1525.015 1321.384 1536.755 1321.384 1536.755 1345.425 1582.601 1345.425 1582.601 1371.702 1613.909 1371.702 1613.909 1361.638 1657.518 1361.638 1657.518 1344.866 1805.676 1344.866 1805.676 1371.702 1867.176 1371.702 1867.176 1345.425 1961.102 1345.425 1961.102 1329.211 2063.415 1329.211 2063.415 1356.607 2108.142 1356.607"
        />

        <g>
          <text transform="translate(1626.336 1490.831)">
            <tspan x="0" y="0">
              BUILDING 5
            </tspan>
          </text>
          <text transform="translate(1591.502 1530.987)">
            <tspan x="0" y="0">
              COMING SOON
            </tspan>
          </text>
        </g>
      </g>

      <g id="building-6">
        <polygon
          onClick={() =>
            click(R["CONDOMINIUM-KEY-PLAN"].pathname, "Building 6")
          }
          fill="#ffffff"
          stroke="#000000"
          strokeWidth={6}
          points="707.63 1241.994 800.997 1241.994 800.997 1248.703 886.537 1248.703 886.537 1219.749 895.483 1219.749 895.483 1241.994 981.464 1241.994 981.464 1285.603 1011.214 1285.603 1011.214 1385.12 1005.064 1385.12 1005.064 1476.251 987.173 1476.251 987.173 1520.978 1004.505 1520.978 1004.505 1658.514 986.614 1658.514 986.614 1702.122 1003.387 1702.122 1003.387 1793.813 1026.309 1793.813 1026.309 1803.876 1008.977 1803.876 1008.977 1892.535 982.7 1892.535 982.7 1936.939 896.042 1936.939 896.042 1959.302 887.097 1959.302 887.097 1929.112 802.674 1929.112 802.674 1938.057 707.63 1938.057 707.63 1895.007 680.912 1895.007 680.912 1794.372 694.771 1794.372 694.771 1751.322 674.084 1751.322 674.084 1742.936 714.339 1742.936 714.339 1657.954 695.33 1657.954 695.33 1522.096 714.339 1522.096 714.339 1436.556 697.125 1436.556 697.125 1385.679 682.589 1385.679 682.589 1285.603 707.63 1285.603 707.63 1241.994"
        />
        <g>
          <text transform="translate(749.94 1586.327)">
            <tspan x="0" y="0">
              BUILDING 6
            </tspan>
          </text>
          <text transform="translate(719.963 1626.483)">
            <tspan x="0" y="0">
              NOW SELLING
            </tspan>
          </text>
        </g>
      </g>

      <g>
        <polygon
          className={s.FutureReleaseShape}
          fill="#d1d2d0"
          stroke="#000000"
          strokeWidth={1}
          points="1297.23 2159.731 1297.23 2247.358 1320.511 2247.358 1320.511 2258.351 1291.41 2258.351 1291.41 2341.774 1297.877 2341.774 1297.877 2434.251 1253.902 2434.251 1253.902 2462.058 1154.312 2462.058 1154.312 2449.771 1104.517 2449.771 1104.517 2431.017 1057.309 2431.017 1057.309 2420.67 1026.309 2420.67 1026.309 2445.891 931.851 2445.891 931.851 2431.664 887.229 2431.664 887.229 2446.538 835.494 2446.538 835.494 2462.058 738.491 2462.058 738.491 2434.251 694.516 2434.251 694.516 2343.008 700.336 2343.008 700.336 2251.884 694.516 2251.884 694.516 2160.055 738.491 2160.055 738.491 2133.54 827.734 2133.54 827.734 2115.433 837.434 2115.433 837.434 2144.534 948.665 2144.534 948.665 2161.348 1041.141 2161.348 1041.141 2145.181 1153.665 2145.181 1153.665 2134.187 1255.195 2134.187 1255.195 2159.731 1297.23 2159.731"
        />
        <text transform="translate(924.079 2281.999)">
          <tspan x="0" y="0">
            FUTURE
          </tspan>
          <tspan x="-9.499" y="40.204">
            RELEASE
          </tspan>
        </text>
      </g>

      <g>
        <polygon
          className={s.FutureReleaseShape}
          fill="#d1d2d0"
          stroke="#000000"
          strokeWidth={1}
          points="2094.854 2134.101 2094.854 2159.165 2139.513 2159.165 2139.513 2245.291 2161.386 2245.291 2161.386 2256.684 2130.854 2256.684 2130.854 2340.7 2138.146 2340.7 2138.146 2434.405 2094.854 2434.405 2094.854 2459.733 1995.968 2459.733 1995.968 2447.165 1952.222 2447.165 1952.222 2469.949 1944.59 2469.949 1944.59 2430.354 1900.728 2430.354 1900.728 2448.532 1805.032 2448.532 1805.032 2420.581 1773.589 2420.581 1773.589 2430.354 1730.753 2430.354 1730.753 2448.532 1679.259 2448.532 1679.259 2462.058 1580.829 2462.058 1580.829 2435.316 1536.627 2435.316 1536.627 2343.266 1543.918 2343.266 1543.918 2255.772 1512.475 2255.772 1512.475 2248.025 1535.259 2248.025 1535.259 2158.709 1578.551 2158.709 1578.551 2134.101 1679.715 2134.101 1679.715 2145.038 1788.627 2145.038 1788.627 2163.266 1882.956 2163.266 1882.956 2145.038 1994.146 2145.038 1994.146 2114.962 2003.715 2114.962 2003.715 2133.646 2094.854 2134.101"
        />
        <text transform="translate(1760.059 2283.055)">
          <tspan x="0" y="0">
            FUTURE
          </tspan>
          <tspan x="-9.499" y="40.204">
            RELEASE
          </tspan>
        </text>
      </g>

      <g>
        <polygon
          className={s.FutureReleaseShape}
          fill="#d1d2d0"
          stroke="#000000"
          strokeWidth={1}
          points="3048.08,2384.02 3048.08,2370.69 2996.03,2370.69 2996.03,2351.49 2950.49,2351.49
					2950.49,2342.06 2917.74,2342.06 2917.74,2370.69 2822.69,2370.69 2822.69,2353.12 2776.49,2353.12 2776.49,2370.2
					2723.79,2370.2 2723.79,2384.02 2627.45,2384.02 2627.45,2357.51 2583.69,2357.51 2583.69,2264.75 2591.09,2264.75
					2591.09,2175.17 2583.69,2175.17 2583.69,2082.4 2628.26,2082.4 2628.26,2055.89 2723.47,2055.89 2723.47,2069.55
					2836.68,2069.55 2836.68,2086.15 2936.34,2086.15 2936.34,2069.07 3047.92,2069.07 3047.92,2055.89 3144.27,2055.89
					3144.27,2082.08 3189.16,2082.08 3189.16,2175.17 3182.76,2175.17 3182.76,2264.26 3189.16,2264.26 3189.16,2357.67
					3144.43,2357.67 3144.43,2384.02 				"
        />
        <text transform="matrix(1 0 0 1 2816.1543 2210.0537)">
          <tspan x="0" y="0" className={s.FutureRelease}>
            FUTURE
          </tspan>
          <tspan x="-9.45" y="40.01" className={s.FutureRelease}>
            RELEASE
          </tspan>
        </text>
      </g>

      <g>
        <polygon
          className={s.SoldOutShape}
          fill="#8c99a1"
          stroke="#000000"
          strokeWidth={6}
          points="2565.57,660.49 2609.84,660.49 2609.85,634.38 2709.38,634.38 2709.42,648 2760.47,647.87
				2760.47,666.17 2844.87,666.17 2844.87,648 2981.11,648 2981.12,666.17 3065.52,666.17 3065.52,648 3116.61,648 3116.61,634.38
				3216.15,634.38 3216.15,660.49 3260.44,660.49 3260.44,753.23 3253.25,753.23 3253.25,842.53 3260.44,842.53 3260.44,935.26
				3216.16,935.26 3216.16,961.37 3116.62,961.37 3116.62,956.83 3025.4,956.83 3025.4,938.66 2983,938.7 2983.01,956.83
				2844.11,956.85 2844.11,938.67 2800.61,938.67 2800.61,956.83 2709.38,956.83 2709.38,961.37 2609.85,961.37 2609.85,935.26
				2565.56,935.26 2565.56,842.53 2572.75,842.53 2572.75,753.23 2565.58,753.22 			"
        />
        <text transform="matrix(1 0 0 1 2825.9944 834.0854)" fill="#ffffff">
          SOLD OUT
        </text>
        <text transform="matrix(1 0 0 1 2820.8811 789.667)" fill="#ffffff">
          BUILDING 1
        </text>
      </g>

      <g>
        <polygon
          className={s.SoldOutShape}
          fill="#8c99a1"
          stroke="#000000"
          strokeWidth={6}
          points="2530.48,1348.17 2646.67,1348.16 2646.67,1343.62 2740.57,1343.63 2740.53,1361.79
				2828.34,1361.79 2828.34,1343.62 2975.95,1343.62 2975.95,1361.8 3019.85,1361.79 3019.85,1343.62 3111.04,1343.62 3111.04,1330
				3219.29,1330 3219.29,1348.17 3257.89,1348.17 3257.89,1435.21 3238.97,1435.21 3238.97,1477.98 3256.93,1477.98
				3257.89,1581.68 3238.97,1581.68 3238.97,1624.45 3257.89,1624.45 3257.89,1711.49 3219.29,1711.49 3219.29,1729.66
				3111.04,1729.66 3111.04,1626.72 3076.6,1626.72 3076.6,1652.55 2979.34,1652.55 2979.34,1634.29 2936.57,1634.29
				2936.57,1652.45 2832.87,1652.45 2832.87,1634.29 2790.11,1634.29 2790.1,1652.45 2692.84,1652.45 2692.84,1626.72
				2658.4,1626.72 2658.4,1666.08 2553.19,1666.08 2553.18,1619.15 2510.04,1619.15 2510.04,1515.45 2548.65,1515.45
				2548.65,1469.65 2530.48,1469.65 			"
        />
        <text transform="matrix(1 0 0 1 2794.2598 1525.413)" fill="#ffffff">
          SOLD OUT
        </text>
        <text transform="matrix(1 0 0 1 2785.1348 1480.9946)" fill="#ffffff">
          BUILDING 2
        </text>
      </g>
      <g>
        <polygon
          fill="#8c99a1"
          stroke="#000000"
          strokeWidth={6}
          points="2136.097 839.451 2129.388 839.451 2129.388 751.116 2136.097 751.116 2136.097 660.544 2093.047 660.544 2093.047 635.468 1992.97 635.468 1992.97 648.244 1942.196 648.244 1942.196 665.575 1900.162 665.575 1900.162 675.639 1866.058 675.639 1866.058 648.244 1772.131 648.244 1772.131 665.575 1730.199 665.575 1730.199 648.244 1677.645 648.244 1677.645 635.468 1578.128 635.468 1578.128 662.221 1535.367 662.221 1535.367 751.116 1541.228 751.116 1541.228 839.451 1535.367 839.451 1511.597 839.451 1511.597 849.515 1535.367 849.515 1535.367 933.378 1579.246 933.378 1579.246 961.891 1668.7 961.891 1668.7 980.341 1677.645 980.341 1677.645 949.032 1787.785 949.032 1787.785 933.378 1885.626 933.378 1885.626 949.032 1994.648 949.032 1994.648 961.891 2093.047 961.891 2093.047 935.614 2136.097 935.614 2136.097 849.515 2161.815 849.515 2161.815 839.451 2136.097 839.451"
        />
        <g>
          <text fill="#ffffff" transform="translate(1737.019 793.003)">
            <tspan x="0" y="0">
              BUILDING 3
            </tspan>
          </text>
          <text fill="#ffffff" transform="translate(1742.381 833.159)">
            <tspan x="0" y="0">
              SOLD OUT
            </tspan>
          </text>
        </g>
      </g>
      <g>
        <polyline
          fill="#8c99a1"
          stroke="#000000"
          strokeWidth={6}
          points="1315.916 684.584 1315.916 776.275 1309.766 776.275 1309.766 862.374 1338.839 862.374 1338.839 871.319 1316.475 871.319 1316.475 959.096 1272.866 959.096 1272.866 985.373 1174.467 985.373 1174.467 973.632 1062.65 973.632 1062.65 957.978 967.046 957.978 967.046 973.073 857.465 973.073 857.465 1106.136 848.52 1106.136 848.52 1073.709 682.471 1073.709 682.471 980.341 719.371 980.341 719.371 888.651 688.062 888.651 688.062 842.599 761.302 842.599 761.302 725.957 855.788 725.957 855.788 670.607 908.901 670.607 908.901 689.616 949.714 689.616 949.714 670.607 1047.554 670.607 1047.554 697.443 1076.068 697.443 1076.068 689.057 1122.472 689.057 1122.472 670.607 1173.349 670.607 1173.349 657.189 1272.866 657.189 1272.866 684.584 1315.916 684.584"
        />
        <g>
          <text fill="#ffffff" transform="translate(939.977 813.081)">
            <tspan x="0" y="0">
              BUILDING 4
            </tspan>
          </text>
          <text fill="#ffffff" transform="translate(946.311 853.237)">
            <tspan x="0" y="0">
              SOLD OUT
            </tspan>
          </text>
        </g>
      </g>
      {/*<g>*/}
      {/*  <polygon*/}
      {/*    className={s.FutureReleaseShape}*/}
      {/*    fill="#d1d2d0"*/}
      {/*    stroke="#000000"*/}
      {/*    strokeWidth={1}*/}
      {/*    points="1392.79,1731 1392.79,1686.71 1348.51,1686.71 1348.51,1625.18 1366.68,1625.18 1366.68,1585.66*/}
      {/*			1348.51,1585.66 1348.51,1475.31 1366.68,1475.31 1366.68,1435.79 1348.51,1435.79 1348.51,1374.26 1392.79,1374.26*/}
      {/*			1392.79,1329.98 1493.31,1329.98 1493.31,1343.61 1584.88,1343.61 1584.88,1361.77 1627.43,1361.77 1627.43,1343.61*/}
      {/*			1776.39,1343.61 1776.39,1361.77 1862.84,1361.77 1862.84,1343.61 1958.06,1343.61 1958.06,1348.14 2074.25,1348.15*/}
      {/*			2074.25,1470.99 2056.08,1470.99 2056.09,1515.44 2103.77,1515.44 2103.77,1620.48 2060.62,1620.48 2060.62,1667.41*/}
      {/*			1954.06,1667.41 1954.06,1628.05 1916.43,1628.04 1916.43,1653.77 1823.11,1653.77 1823.1,1635.62 1779.04,1635.63*/}
      {/*			1779.04,1653.79 1668.7,1653.79 1668.7,1635.62 1624.63,1635.62 1624.63,1653.79 1531.31,1654.08 1531.31,1635.62*/}
      {/*			1493.31,1635.62 1493.31,1731 				"*/}
      {/*  />*/}

      {/*  <text transform="matrix(1 0 0 1 1655.9766 1487.0557)">*/}
      {/*    <tspan x="0" y="0" className={s.FutureRelease}>*/}
      {/*      FUTURE*/}
      {/*    </tspan>*/}
      {/*    <tspan x="-9.44" y="39.95" className={s.FutureRelease}>*/}
      {/*      RELEASE*/}
      {/*    </tspan>*/}
      {/*  </text>*/}
      {/*</g>*/}

      {/*<g>*/}
      {/*  <polygon*/}
      {/*    className={s.FutureReleaseShape}*/}
      {/*    fill="#d1d2d0"*/}
      {/*    stroke="#000000"*/}
      {/*    strokeWidth={1}*/}
      {/*    points="861.46,1888.42 861.46,1881.23 773.51,1881.23 773.51,1888.42 679.42,1888.41 679.42,1844.13*/}
      {/*			653.31,1844.13 653.31,1743.24 666.94,1743.23 666.94,1692.14 685.1,1692.14 685.17,1609.1 667.14,1609.09 666.94,1471.5*/}
      {/*			685.11,1471.47 685.11,1388.45 666.94,1388.45 666.94,1337.36 653.32,1337.36 653.27,1236.47 679.44,1236.47 679.47,1192.18*/}
      {/*			773.53,1192.25 773.53,1199.37 861.48,1199.33 861.48,1192.19 955.56,1192.19 955.56,1236.47 981.67,1236.47 981.68,1337.36*/}
      {/*			977.13,1337.36 977.13,1428.6 958.97,1428.59 958.96,1470.74 977.13,1470.75 977.13,1611 958.96,1610.99 958.95,1652.03*/}
      {/*			977.26,1652.03 977.12,1743.25 981.66,1743.25 981.68,1844.14 955.54,1844.13 955.54,1888.42 				"*/}
      {/*  />*/}
      {/*  <text transform="matrix(1 0 0 1 747.3115 1530.4136)">*/}
      {/*    <tspan x="0" y="0" className={s.FutureRelease}>*/}
      {/*      FUTURE*/}
      {/*    </tspan>*/}
      {/*    <tspan x="-9.44" y="39.95" className={s.FutureRelease}>*/}
      {/*      RELEASE*/}
      {/*    </tspan>*/}
      {/*  </text>*/}
      {/*</g>*/}

      {/*<g*/}
      {/*  className={s.Available}*/}
      {/*  onClick={() => {*/}
      {/*    click(R["CONDOMINIUM-KEY-PLAN"].pathname, "Building 4");*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <polyline*/}
      {/*    id="CONDO-BUILDING-4"*/}
      {/*    className={s.Hot}*/}
      {/*    points="1361.009,981.457 1361.009,877.231 1352.926,877.231 1352.926,776.834*/}
      {/*1361.009,776.848 1361.009,672.609 1311.236,672.609 1311.236,643.255 1199.353,643.255 1199.339,658.57 1141.909,658.57*/}
      {/*1141.909,678.99 1092.136,678.99 1092.136,689.2 1057.252,689.2 1057.252,658.57 949.198,658.57 949.184,678.99 901.139,678.99*/}
      {/*901.139,658.57 841.994,658.57 841.994,719.829 735.641,719.829 735.641,849.154 653.949,849.154 653.949,904.471 687.969,904.471*/}
      {/*687.969,1004.881 653.949,1004.881 653.962,1112.936 843.696,1112.936 843.709,998.048 967.916,998.048 967.916,977.628*/}
      {/*1075.119,977.628 1075.119,998.048 1199.353,998.048 1199.353,1010.81 1311.236,1010.81 1311.236,981.457 1360.969,981.457*/}
      {/*1361.009,981.457 	"*/}
      {/*  />*/}
      {/*  <text transform="matrix(1 0 0 1 940.8887 850.0854)">BUILDING 4</text>*/}
      {/*</g>*/}

      {/*<g>*/}
      {/*  <polygon*/}
      {/*    className={s.FutureReleaseShape}*/}
      {/*    fill="#d1d2d0"*/}
      {/*    stroke="#000000"*/}
      {/*    strokeWidth={1}*/}
      {/*    points="1216.51,2454.4 1165.41,2454.4 1165.41,2436.24 1082.37,2436.17 1082.4,2454.2 944.77,2454.4*/}
      {/*			944.76,2436.24 861.72,2436.24 861.72,2454.4 810.63,2454.4 810.64,2468.03 709.74,2468.03 709.74,2441.91 665.46,2441.88*/}
      {/*			665.52,2347.82 672.64,2347.82 672.64,2259.87 665.46,2259.87 665.46,2165.79 709.74,2165.79 709.74,2139.68 810.63,2139.67*/}
      {/*			810.63,2144.21 901.86,2144.22 901.86,2162.37 944,2162.38 944.01,2144.22 1084.26,2144.22 1084.26,2162.38 1125.3,2162.38*/}
      {/*			1125.32,2144.08 1216.52,2144.21 1216.52,2139.68 1317.4,2139.68 1317.4,2165.79 1361.69,2165.79 1361.69,2259.87*/}
      {/*			1354.5,2259.87 1354.5,2347.82 1361.69,2347.82 1361.69,2441.91 1317.4,2441.91 1317.4,2468.03 1216.51,2468.12 				"*/}
      {/*  />*/}
      {/*  <text transform="matrix(1 0 0 1 943.4097 2294.0059)">*/}
      {/*    <tspan x="0" y="0" className={s.FutureRelease}>*/}
      {/*      FUTURE*/}
      {/*    </tspan>*/}
      {/*    <tspan x="-9.44" y="39.95" className={s.FutureRelease}>*/}
      {/*      RELEASE*/}
      {/*    </tspan>*/}
      {/*  </text>*/}
      {/*</g>*/}

      {/*<g>*/}
      {/*  <polygon*/}
      {/*    className={s.FutureReleaseShape}*/}
      {/*    fill="#d1d2d0"*/}
      {/*    stroke="#000000"*/}
      {/*    strokeWidth={1}*/}
      {/*    points="2138.46,2468.03 2138.45,2454.4 2087.35,2454.4 2087.35,2436.24 2004.32,2436.17 2004.3,2454.21*/}
      {/*			1866.71,2454.1 1866.71,2436.24 1783.67,2436.25 1783.66,2454.4 1732.57,2454.4 1732.58,2468.03 1631.69,2468.03*/}
      {/*			1631.68,2441.91 1587.4,2441.88 1587.35,2347.82 1594.59,2347.82 1594.54,2259.87 1587.4,2259.87 1587.4,2165.79*/}
      {/*			1631.68,2165.79 1631.68,2139.68 1732.57,2139.67 1732.57,2144.21 1823.8,2144.22 1823.8,2162.37 1865.95,2162.37*/}
      {/*			1865.95,2144.22 2006.2,2144.22 2006.2,2162.38 2047.24,2162.38 2047.27,2144.08 2138.46,2144.08 2138.46,2139.68*/}
      {/*			2239.34,2139.68 2239.35,2165.79 2283.63,2165.78 2283.63,2259.87 2276.44,2259.87 2276.44,2347.82 2283.63,2347.82*/}
      {/*			2283.63,2441.91 2239.34,2441.91 2239.34,2468.03 				"*/}
      {/*  />*/}
      {/*  <text transform="matrix(1 0 0 1 1865.3242 2294.0059)">*/}
      {/*    <tspan x="0" y="0" className={s.FutureRelease}>*/}
      {/*      FUTURE*/}
      {/*    </tspan>*/}
      {/*    <tspan x="-9.44" y="39.95" className={s.FutureRelease}>*/}
      {/*      RELEASE*/}
      {/*    </tspan>*/}
      {/*  </text>*/}
      {/*</g>*/}

      {/*<g>*/}
      {/*  <polygon*/}
      {/*    className={s.FutureReleaseShape}*/}
      {/*    fill="#d1d2d0"*/}
      {/*    stroke="#000000"*/}
      {/*    strokeWidth={1}*/}
      {/*    points="3048.08,2384.02 3048.08,2370.69 2996.03,2370.69 2996.03,2351.49 2950.49,2351.49*/}
      {/*			2950.49,2342.06 2917.74,2342.06 2917.74,2370.69 2822.69,2370.69 2822.69,2353.12 2776.49,2353.12 2776.49,2370.2*/}
      {/*			2723.79,2370.2 2723.79,2384.02 2627.45,2384.02 2627.45,2357.51 2583.69,2357.51 2583.69,2264.75 2591.09,2264.75*/}
      {/*			2591.09,2175.17 2583.69,2175.17 2583.69,2082.4 2628.26,2082.4 2628.26,2055.89 2723.47,2055.89 2723.47,2069.55*/}
      {/*			2836.68,2069.55 2836.68,2086.15 2936.34,2086.15 2936.34,2069.07 3047.92,2069.07 3047.92,2055.89 3144.27,2055.89*/}
      {/*			3144.27,2082.08 3189.16,2082.08 3189.16,2175.17 3182.76,2175.17 3182.76,2264.26 3189.16,2264.26 3189.16,2357.67*/}
      {/*			3144.43,2357.67 3144.43,2384.02 				"*/}
      {/*  />*/}
      {/*  <text transform="matrix(1 0 0 1 2816.1543 2210.0537)">*/}
      {/*    <tspan x="0" y="0" className={s.FutureRelease}>*/}
      {/*      FUTURE*/}
      {/*    </tspan>*/}
      {/*    <tspan x="-9.45" y="40.01" className={s.FutureRelease}>*/}
      {/*      RELEASE*/}
      {/*    </tspan>*/}
      {/*  </text>*/}
      {/*</g>*/}

      {/*<g>*/}
      {/*  <polygon*/}
      {/*    className={s.SoldOutShape}*/}
      {/*    fill="#8c99a1"*/}
      {/*    stroke="#000000"*/}
      {/*    strokeWidth={6}*/}
      {/*    points="2565.57,660.49 2609.84,660.49 2609.85,634.38 2709.38,634.38 2709.42,648 2760.47,647.87*/}
      {/*		2760.47,666.17 2844.87,666.17 2844.87,648 2981.11,648 2981.12,666.17 3065.52,666.17 3065.52,648 3116.61,648 3116.61,634.38*/}
      {/*		3216.15,634.38 3216.15,660.49 3260.44,660.49 3260.44,753.23 3253.25,753.23 3253.25,842.53 3260.44,842.53 3260.44,935.26*/}
      {/*		3216.16,935.26 3216.16,961.37 3116.62,961.37 3116.62,956.83 3025.4,956.83 3025.4,938.66 2983,938.7 2983.01,956.83*/}
      {/*		2844.11,956.85 2844.11,938.67 2800.61,938.67 2800.61,956.83 2709.38,956.83 2709.38,961.37 2609.85,961.37 2609.85,935.26*/}
      {/*		2565.56,935.26 2565.56,842.53 2572.75,842.53 2572.75,753.23 2565.58,753.22 			"*/}
      {/*  />*/}
      {/*  <text*/}
      {/*    transform="matrix(1 0 0 1 2825.9944 834.0854)"*/}
      {/*    className={s.SoldOut}*/}
      {/*    fill="#ffffff"*/}
      {/*  >*/}
      {/*    SOLD OUT*/}
      {/*  </text>*/}
      {/*  <text*/}
      {/*    transform="matrix(1 0 0 1 2820.8811 789.667)"*/}
      {/*    className={s.SoldOut}*/}
      {/*    fill="#ffffff"*/}
      {/*  >*/}
      {/*    BUILDING 1*/}
      {/*  </text>*/}
      {/*</g>*/}

      {/*<g>*/}
      {/*  <polygon*/}
      {/*    className={s.SoldOutShape}*/}
      {/*    fill="#8c99a1"*/}
      {/*    stroke="#000000"*/}
      {/*    strokeWidth={6}*/}
      {/*    points="2530.48,1348.17 2646.67,1348.16 2646.67,1343.62 2740.57,1343.63 2740.53,1361.79*/}
      {/*		2828.34,1361.79 2828.34,1343.62 2975.95,1343.62 2975.95,1361.8 3019.85,1361.79 3019.85,1343.62 3111.04,1343.62 3111.04,1330*/}
      {/*		3219.29,1330 3219.29,1348.17 3257.89,1348.17 3257.89,1435.21 3238.97,1435.21 3238.97,1477.98 3256.93,1477.98*/}
      {/*		3257.89,1581.68 3238.97,1581.68 3238.97,1624.45 3257.89,1624.45 3257.89,1711.49 3219.29,1711.49 3219.29,1729.66*/}
      {/*		3111.04,1729.66 3111.04,1626.72 3076.6,1626.72 3076.6,1652.55 2979.34,1652.55 2979.34,1634.29 2936.57,1634.29*/}
      {/*		2936.57,1652.45 2832.87,1652.45 2832.87,1634.29 2790.11,1634.29 2790.1,1652.45 2692.84,1652.45 2692.84,1626.72*/}
      {/*		2658.4,1626.72 2658.4,1666.08 2553.19,1666.08 2553.18,1619.15 2510.04,1619.15 2510.04,1515.45 2548.65,1515.45*/}
      {/*		2548.65,1469.65 2530.48,1469.65 			"*/}
      {/*  />*/}
      {/*  <text*/}
      {/*    transform="matrix(1 0 0 1 2794.2598 1525.413)"*/}
      {/*    className={s.SoldOut}*/}
      {/*    fill="#ffffff"*/}
      {/*  >*/}
      {/*    SOLD OUT*/}
      {/*  </text>*/}
      {/*  <text*/}
      {/*    transform="matrix(1 0 0 1 2785.1348 1480.9946)"*/}
      {/*    className={s.SoldOut}*/}
      {/*    fill="#ffffff"*/}
      {/*  >*/}
      {/*    BUILDING 2*/}
      {/*  </text>*/}
      {/*</g>*/}

      {/*<g*/}
      {/*  className={s.Available}*/}
      {/*  onClick={() => {*/}
      {/*    click(R["CONDOMINIUM-KEY-PLAN"].pathname, "Building 3");*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <polygon*/}
      {/*    className={s.Hot}*/}
      {/*    points="1562.68,733.44 1630.8,733.44 1630.8,643.26 1891.94,643.26 1891.94,665.96 1982.77,665.96*/}
      {/*			1982.77,643.26 2277.98,643.26 2277.98,688.62 2312.04,688.62 2312.04,906.72 2277.98,906.72 2277.98,952.08 1982.77,952.08*/}
      {/*			1982.77,929.37 1891.94,929.37 1891.94,952.08 1562.68,952.08 				"*/}
      {/*  />*/}
      {/*  <text transform="matrix(1 0 0 1 1836.4265 817.5951)">BUILDING 3</text>*/}
      {/*</g>*/}
    </g>
  );
};

export default OtherBuildings;
