import S from "../BuildingsKey.module.sass";
import { Props } from "../Props";

const Level1 = ({ unitStyle, click }: Props) => {
  return (
    <svg viewBox="0 0 480 290" className={S.Keyplate}>
      {/*<text*/}
      {/*  transform="matrix(1 0 0 1 346.6914 117.75)"*/}
      {/*  className={S.AmenityLabel2}*/}
      {/*>*/}
      {/*  AMENITY*/}
      {/*</text>*/}
      <g className={S.Lineworks}>
        <polygon points="446.7 109.67 374.87 109.73 374.88 128.66 361.6 128.67 361.52 39.5 420.29 39.45 420.31 54.99 446.65 54.96 446.7 109.67" />
        <rect x="374.89" y="109.72" width="67.56" height="54.04" />
        <polygon points="374.9 144.87 361.61 144.89 361.69 234.06 420.46 234.01 420.45 218.47 446.79 218.45 446.75 163.73 374.91 163.79 374.9 144.87" />
        <polygon points="361.59 128.67 302.14 128.72 302.06 39.55 334.49 39.52 334.5 50.33 361.52 50.31 361.59 128.67" />
        <polygon points="302.14 128.72 267.01 128.75 267 122 242.22 122.02 242.16 47.71 302.07 47.66 302.14 128.72" />
        <rect x="177.79" y="47.75" width="36.25" height="81.07" />
        <polygon points="177.83 128.83 118.39 128.89 118.32 50.52 145.33 50.5 145.33 39.69 177.75 39.66 177.83 128.83" />
        <polygon points="118.38 128.89 105.1 128.9 105.08 109.99 33.25 110.05 33.2 55.32 59.55 55.3 59.54 39.76 118.3 39.71 118.38 128.89" />
        <rect x="37.55" y="110.02" width="67.56" height="54.04" />
        <polygon points="105.12 145.11 118.4 145.1 118.48 234.28 59.71 234.33 59.69 218.79 33.35 218.81 33.3 164.09 105.13 164.03 105.12 145.11" />
        <polygon points="129.87 231.56 173.21 231.52 173.21 220.72 199.56 220.7 199.49 145.02 140.49 145.08 140.53 191.46 129.72 191.47 129.87 231.56" />
        <polygon points="350.42 231.37 306.96 231.41 306.95 220.6 280.6 220.62 280.54 144.96 339.54 144.91 339.58 191.29 350.38 191.28 350.42 231.37" />
        <rect x="242.22" y="122.01" width="24.79" height="6.75" />
        <rect x="235.88" y="85.53" width="6.31" height="4.06" />
        <rect x="235.92" y="124.72" width="6.3" height="4.06" />
        <rect x="236.34" y="90.27" width="5.41" height="8.11" />
        <rect x="236.35" y="98.82" width="5.41" height="8.11" />
        <rect x="236.35" y="107.38" width="5.41" height="8.11" />
        <rect x="236.36" y="115.94" width="5.41" height="8.11" />
        <rect x="199.54" y="172.01" width="56.74" height="59.46" />
        <rect x="274.03" y="144.96" width="6.51" height="13.52" />
        <rect x="251.05" y="144.98" width="5.18" height="27.02" />
        <polyline points="256.22 147.57 252.84 147.58 252.86 171.1 256.23 171.1" />
        <line x1="256.23" y1="170.08" x2="252.85" y2="170.08" />
        <line x1="256.23" y1="168.65" x2="252.85" y2="168.65" />
        <line x1="256.23" y1="167.22" x2="252.85" y2="167.22" />
        <line x1="256.23" y1="165.78" x2="252.85" y2="165.79" />
        <line x1="256.23" y1="164.35" x2="252.85" y2="164.35" />
        <line x1="256.23" y1="162.92" x2="252.85" y2="162.92" />
        <line x1="256.23" y1="161.49" x2="252.85" y2="161.49" />
        <line x1="256.22" y1="160.05" x2="252.85" y2="160.06" />
        <line x1="256.22" y1="158.62" x2="252.84" y2="158.62" />
        <line x1="256.22" y1="157.19" x2="252.84" y2="157.19" />
        <line x1="256.22" y1="155.76" x2="252.84" y2="155.76" />
        <line x1="256.22" y1="154.32" x2="252.84" y2="154.33" />
        <line x1="256.22" y1="152.89" x2="252.84" y2="152.89" />
        <line x1="256.22" y1="151.46" x2="252.84" y2="151.46" />
        <line x1="256.22" y1="150.02" x2="252.84" y2="150.03" />
        <line x1="256.21" y1="148.59" x2="252.84" y2="148.59" />
        <rect x="228.64" y="154" width="18.69" height="13.51" />
        <rect x="203.43" y="154.02" width="18.69" height="13.51" />
        <line x1="247.33" y1="167.5" x2="228.63" y2="154.01" />
        <line x1="247.32" y1="153.99" x2="228.64" y2="167.52" />
        <line x1="222.12" y1="154.01" x2="203.44" y2="167.54" />
        <line x1="222.13" y1="167.52" x2="203.43" y2="154.03" />
        <line x1="140.48" y1="160.84" x2="130.56" y2="160.85" />
        <line x1="140.48" y1="163.32" x2="130.56" y2="163.33" />
        <line x1="140.48" y1="165.79" x2="130.56" y2="165.8" />
        <line x1="140.48" y1="168.27" x2="130.56" y2="168.28" />
        <line x1="140.48" y1="170.75" x2="130.56" y2="170.76" />
        <line x1="140.49" y1="173.23" x2="130.57" y2="173.23" />
        <line x1="140.49" y1="175.7" x2="130.57" y2="175.71" />
        <line x1="140.49" y1="178.18" x2="130.57" y2="178.19" />
        <line x1="140.49" y1="180.66" x2="130.57" y2="180.66" />
        <line x1="129.73" y1="194.64" x2="118.45" y2="194.65" />
        <line x1="129.73" y1="197.12" x2="118.45" y2="197.13" />
        <line x1="129.73" y1="199.6" x2="118.45" y2="199.61" />
        <line x1="129.73" y1="202.07" x2="118.45" y2="202.08" />
        <line x1="129.73" y1="204.55" x2="118.45" y2="204.56" />
        <line x1="129.74" y1="207.03" x2="118.46" y2="207.04" />
        <line x1="129.74" y1="209.51" x2="118.46" y2="209.52" />
        <line x1="129.74" y1="211.98" x2="118.46" y2="211.99" />
        <line x1="129.74" y1="214.46" x2="118.46" y2="214.47" />
        <line x1="251.04" y1="149.93" x2="199.5" y2="149.98" />
        <line x1="242.16" y1="47.71" x2="214.01" y2="47.73" />
        <line x1="214.04" y1="70.7" x2="242.18" y2="70.68" />
        <line x1="280.59" y1="211.16" x2="256.29" y2="211.18" />
        <polyline points="280.6 220.62 280.61 231.43 256.31 231.45" />
        <line x1="361.61" y1="144.89" x2="339.53" y2="144.91" />
        <line x1="118.4" y1="145.1" x2="140.49" y2="145.08" />
        <rect x="128.76" y="159.93" width="1.8" height="21.62" />
        <line x1="128.75" y1="160.85" x2="118.42" y2="160.86" />
        <line x1="128.76" y1="163.33" x2="118.42" y2="163.34" />
        <line x1="128.76" y1="165.8" x2="118.42" y2="165.81" />
        <line x1="128.76" y1="168.28" x2="118.42" y2="168.29" />
        <line x1="128.76" y1="170.76" x2="118.43" y2="170.77" />
        <line x1="128.77" y1="173.24" x2="118.43" y2="173.25" />
        <line x1="128.77" y1="175.71" x2="118.43" y2="175.72" />
        <line x1="128.77" y1="178.19" x2="118.43" y2="178.2" />
        <line x1="128.77" y1="180.67" x2="118.43" y2="180.68" />
        <line x1="129.87" y1="231.56" x2="118.47" y2="231.57" />
        <polyline points="33.26 110.05 21.34 110.06 21.39 164.1 33.3 164.09" />
        <polyline points="145.32 39.69 145.32 34.28 118.3 34.31 118.3 39.71" />
        <polyline points="173.22 231.52 173.22 239.62 199.57 239.6 199.57 231.5" />
        <polyline points="199.55 231.49 199.57 255.9 254.99 255.85 254.96 231.45" />
        <polyline points="280.61 231.43 280.62 239.54 306.97 239.51 306.96 231.41" />
        <line x1="361.69" y1="231.36" x2="350.43" y2="231.36" />
        <polyline points="446.7 109.68 458.61 109.67 458.66 163.72 446.75 163.73" />
        <polyline points="361.52 39.5 361.51 34.1 334.48 34.12 334.49 39.52" />
        <polyline points="302.06 39.55 302.06 34.15 266.93 34.18 266.94 47.69" />
        <polyline points="177.75 39.66 177.75 34.26 212.88 34.23 212.89 47.74" />
        <polyline points="59.54 39.76 33.19 39.79 33.2 55.32" />
        <polyline points="33.37 218.81 33.39 234.35 59.71 234.32" />
        <polyline points="420.46 234.01 446.81 233.99 446.77 218.45" />
        <polyline points="446.65 54.96 446.64 39.43 420.29 39.45" />
        <rect x="349.49" y="159.74" width="1.8" height="21.62" />
        <line x1="361.62" y1="160.65" x2="351.28" y2="160.66" />
        <line x1="361.63" y1="163.12" x2="351.28" y2="163.13" />
        <line x1="361.63" y1="165.6" x2="351.28" y2="165.61" />
        <line x1="361.63" y1="168.08" x2="351.29" y2="168.09" />
        <line x1="361.63" y1="170.55" x2="351.29" y2="170.56" />
        <line x1="361.64" y1="173.03" x2="351.29" y2="173.04" />
        <line x1="361.64" y1="175.51" x2="351.29" y2="175.52" />
        <line x1="361.64" y1="177.99" x2="351.29" y2="178" />
        <line x1="361.64" y1="180.46" x2="351.3" y2="180.47" />
        <line x1="361.65" y1="194.43" x2="350.39" y2="194.44" />
        <line x1="361.66" y1="196.91" x2="350.39" y2="196.92" />
        <line x1="361.66" y1="199.39" x2="350.39" y2="199.4" />
        <line x1="361.66" y1="201.86" x2="350.39" y2="201.87" />
        <line x1="361.66" y1="204.34" x2="350.4" y2="204.35" />
        <line x1="361.66" y1="206.82" x2="350.4" y2="206.83" />
        <line x1="361.67" y1="209.29" x2="350.4" y2="209.3" />
        <line x1="361.67" y1="211.77" x2="350.4" y2="211.78" />
        <line x1="361.67" y1="214.25" x2="350.41" y2="214.26" />
        <line x1="349.49" y1="180.47" x2="339.57" y2="180.48" />
        <line x1="349.49" y1="178" x2="339.56" y2="178" />
        <line x1="349.49" y1="175.52" x2="339.56" y2="175.53" />
        <line x1="349.49" y1="173.04" x2="339.56" y2="173.05" />
        <line x1="349.49" y1="170.57" x2="339.56" y2="170.57" />
        <line x1="349.48" y1="168.09" x2="339.56" y2="168.1" />
        <line x1="349.48" y1="165.61" x2="339.55" y2="165.62" />
        <line x1="349.48" y1="163.13" x2="339.55" y2="163.14" />
        <line x1="349.48" y1="160.66" x2="339.55" y2="160.67" />
      </g>

      <g className={S.Units} id="building-6-units-level-1">
        <g
          id="UNIT-101-D"
          onClick={() => click("D")}
          className={unitStyle("D", "101")}
        >
          <polygon
            className={S.Hot}
            points="129.87 231.56 173.21 231.52 173.21 220.72 199.56 220.7 199.49 145.02 140.49 145.08 140.54 191.46 129.73 191.47 129.87 231.56"
          />
          <g className={S.Label}>
            <text transform="translate(157.52 197.84)">101</text>
            <text transform="translate(163.19 181.61) scale(1.04 1)">D</text>
          </g>
        </g>
        <g
          id="UNIT-102-G"
          onClick={() => click("G")}
          className={unitStyle("G", "102")}
        >
          <polygon
            className={S.Hot}
            points="105.12 145.11 118.41 145.1 118.48 234.28 59.71 234.33 59.7 218.79 33.35 218.81 33.3 164.09 105.13 164.03 105.12 145.11"
          />
          <g className={S.Label}>
            <text transform="translate(66.99 209.86)">102</text>
            <text transform="translate(74.4 193.62) scale(1.04 1)">G</text>
          </g>
        </g>
        <g
          id="UNIT-103-B"
          onClick={() => click("B")}
          className={unitStyle("B", "103")}
        >
          <rect
            className={S.Hot}
            x="37.56"
            y="110.02"
            width="67.56"
            height="54.04"
          />
          <g className={S.Label}>
            <text transform="translate(57.26 149.81)">103</text>
            <text transform="translate(65.23 133.58) scale(1.04 1)">B</text>
          </g>
        </g>
        <g
          id="UNIT-104-G"
          onClick={() => click("G")}
          className={unitStyle("G", "104")}
        >
          <polygon
            className={S.Hot}
            points="118.38 128.89 105.1 128.9 105.09 109.99 33.26 110.05 33.21 55.32 59.55 55.3 59.54 39.76 118.31 39.71 118.38 128.89"
          />
          <g className={S.Label}>
            <text transform="translate(66.43 89.59)">104</text>
            <text transform="translate(74.4 73.36) scale(1.04 1)">G</text>
          </g>
        </g>
        <g
          id="UNIT-105-E"
          onClick={() => click("E")}
          className={unitStyle("E", "105")}
        >
          <polygon
            className={S.Hot}
            points="177.83 128.83 118.39 128.89 118.32 50.52 145.34 50.5 145.33 39.69 177.75 39.66 177.83 128.83"
          />
          <g className={S.Label}>
            <text transform="translate(133.96 96.9)">105</text>
            <text transform="translate(142.42 80.66) scale(1.04 1)">E</text>
          </g>
        </g>
        <g
          id="UNIT-106-A"
          onClick={() => click("A")}
          className={unitStyle("A", "106")}
        >
          <rect
            className={S.Hot}
            x="177.8"
            y="47.75"
            width="36.25"
            height="81.07"
          />
          <g className={S.Label}>
            <text transform="translate(181.54 96.9)">106</text>
            <text transform="translate(189.25 80.66) scale(1.04 1)">A</text>
          </g>
        </g>
        <g
          id="UNIT-107-C1"
          onClick={() => click("C1")}
          className={unitStyle("C1", "107")}
        >
          <polygon
            className={S.Hot}
            points="302.14 128.72 267.01 128.75 267.01 122 242.22 122.02 242.16 47.71 302.07 47.66 302.14 128.72"
          />
          <g className={S.Label}>
            <text transform="translate(258.15 96.9)">107</text>
            <text transform="translate(262.57 80.66) scale(1.04 1)">C1</text>
          </g>
        </g>
        <g
          id="UNIT-108-F"
          onClick={() => click("F")}
          className={unitStyle("F", "108")}
        >
          <polygon
            className={S.Hot}
            points="361.59 128.67 302.14 128.72 302.07 39.55 334.49 39.52 334.5 50.33 361.52 50.31 361.59 128.67"
          />
          <g className={S.Label}>
            <text transform="translate(317.66 96.9)">108</text>
            <text transform="translate(326.29 80.66) scale(1.04 1)">F</text>
          </g>
        </g>
        <g
          id="UNIT-109-G"
          onClick={() => click("G")}
          className={unitStyle("G", "109")}
        >
          <polygon
            className={S.Hot}
            points="446.7 109.67 374.87 109.73 374.89 128.66 361.6 128.67 361.52 39.5 420.3 39.45 420.31 54.99 446.66 54.96 446.7 109.67"
          />
          <g className={S.Label}>
            <text transform="translate(386.81 89.59)">109</text>
            <text transform="translate(394.57 73.36) scale(1.04 1)">G</text>
          </g>
        </g>
        <g
          id="UNIT-110-B"
          onClick={() => click("B")}
          className={unitStyle("B", "110")}
        >
          <rect
            className={S.Hot}
            x="374.9"
            y="109.72"
            width="67.56"
            height="54.04"
          />
          <g className={S.Label}>
            <text transform="translate(396.39 149.52)">110</text>
            <text transform="translate(402.57 133.28) scale(1.04 1)">B</text>
          </g>
        </g>
        <g
          id="UNIT-111-G"
          onClick={() => click("G")}
          className={unitStyle("G", "111")}
        >
          <polygon
            className={S.Hot}
            points="374.9 144.87 361.61 144.89 361.69 234.06 420.47 234.01 420.45 218.47 446.8 218.45 446.75 163.73 374.92 163.79 374.9 144.87"
          />
          <g className={S.Label}>
            <text transform="translate(391.57 209.86)">111</text>
            <text transform="translate(394.57 193.62) scale(1.04 1)">G</text>
          </g>
        </g>
        <g
          id="UNIT-112-D"
          onClick={() => click("D")}
          className={unitStyle("D", "112")}
        >
          <polygon
            className={S.Hot}
            points="350.42 231.37 306.97 231.41 306.96 220.6 280.61 220.62 280.54 144.96 339.54 144.91 339.58 191.29 350.39 191.28 350.42 231.37"
          />
          <g className={S.Label}>
            <text transform="translate(299.74 196.28)">112</text>
            <text transform="translate(304.51 180.04) scale(1.04 1)">D</text>
          </g>
        </g>
      </g>

      <g className={S.LevelLabel}>
        <text transform="matrix(1 0 0 1 380.5 274.6689)">Level 1</text>
      </g>
    </svg>
  );
};

export default Level1;
