import { render } from "@wordpress/element";
// @ts-ignore
import { MemoryRouter as Router } from "react-router-dom";
// import {createHashHistory} from 'history';
import Root from "./container/__Root";
import { store } from "./store";
import * as serviceWorker from "./serviceWorker";
import "./sass/app.global.sass";

const applicationContainer = document.getElementById(
  "hv-condos-building-6-container"
);

// const history = createHashHistory({window});

applicationContainer &&
  render(
    <Router initialEntries={["/condominiums-application"]}>
      <Root store={store} />
    </Router>,
    applicationContainer
  );

serviceWorker.unregister();
