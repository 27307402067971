import { I__Plan } from "./interface";
// import Building1 from "./building1";
// import Building2 from "./building2";
// import Building3 from "./building3";
// import Building4 from "./building4";
import Building5 from "./building5";
import Building6 from "./building6";

const Floorplans: Array<I__Plan> = [
  // ...Building1,
  // ...Building2,
  // ...Building3,
  // ...Building4,
  ...Building5,
  ...Building6,
];

export default Floorplans;
