import _ from "lodash";
import S from "../SVG.module.sass";

import BUILDINGS, { I__Buildings } from "../../../../constants/buildings";
import { T__Plan_Name } from "../../../../constants/floorplans/interface";

type Props = {
  unitClass: (build: I__Buildings | undefined, unit: string) => string;
  click: (planName: T__Plan_Name) => void;
};

const Building11 = ({ unitClass }: Props) => {
  const b = _.find(BUILDINGS, { title: "Building 11" });
  return (
    <g id="BUILDING-11">
      <polygon
        id="BUILDING-11-PATCH"
        className={S.Patch}
        points="4056.14,2007.23 4056.14,2010.04 4047.4,2010.04 4047.4,2039.41
			4109.44,2039.41 4109.44,2034.38 4109.44,2010.04 4109.44,2007.23 		"
      />
      <g className={unitClass(b, "120")} id="UNIT-120-C2">
        <polygon
          className={S.Hot}
          points="4047.4,2023.63 4002.84,2023.63 4002.84,2148.77 4100.21,2148.77 4100.21,2129.76 4109.44,2129.76
				4109.44,2039.41 4047.4,2039.41 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4031.5449 2090.1797)"
            className={S.Number}
          >
            120
          </text>
          <text
            transform="matrix(1 0 0 1 4036.6921 2119.5586)"
            className={S.Plan}
          >
            C2
          </text>
        </g>
      </g>
      <g className={unitClass(b, "121")} id="UNIT-121-C">
        <polygon
          className={S.Hot}
          points="4002.84,2256.65 4109.44,2256.65 4109.44,2166.41 4100.21,2166.41 4100.21,2148.77 4002.84,2148.77
							"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4035.8086 2199.4395)"
            className={S.Number}
          >
            121
          </text>
          <text
            transform="matrix(1 0 0 1 4044.4783 2228.8184)"
            className={S.Plan}
          >
            C
          </text>
        </g>
      </g>
      <g className={unitClass(b, "122")} id="UNIT-122-C">
        <polygon
          className={S.Hot}
          points="4002.84,2367.05 4100.21,2367.05 4100.21,2348.16 4109.44,2348.16 4109.44,2256.65 4002.84,2256.65
							"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4032.9871 2308.6997)"
            className={S.Number}
          >
            122
          </text>
          <text
            transform="matrix(1 0 0 1 4044.478 2338.0786)"
            className={S.Plan}
          >
            C
          </text>
        </g>
      </g>
      <g className={unitClass(b, "123")} id="UNIT-123-C2">
        <polygon
          className={S.Hot}
          points="4002.84,2489.66 4056.89,2489.66 4056.89,2475.82 4109.44,2475.82 4109.44,2383.28 4100.21,2383.28
				4100.21,2367.05 4002.84,2367.05 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4032.9241 2417.9595)"
            className={S.Number}
          >
            123
          </text>
          <text
            transform="matrix(1 0 0 1 4036.6924 2447.3384)"
            className={S.Plan}
          >
            C2
          </text>
        </g>
      </g>
      <g className={unitClass(b, "124")} id="UNIT-124-C2">
        <polygon
          className={S.Hot}
          points="3909.23,2367.05 3909.23,2383.28 3900.52,2383.28 3900.52,2475.82 3952.56,2475.82 3952.56,2503.5
				3961.27,2503.5 3961.27,2489.66 4002.84,2489.66 4002.84,2367.05 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3935.4988 2417.9595)"
            className={S.Number}
          >
            124
          </text>
          <text
            transform="matrix(1 0 0 1 3940.0991 2447.3384)"
            className={S.Plan}
          >
            C2
          </text>
        </g>
      </g>
      <g className={unitClass(b, "125")} id="UNIT-125-C">
        <polygon
          className={S.Hot}
          points="4002.84,2256.65 3900.52,2256.65 3900.52,2348.16 3909.23,2348.16 3909.23,2367.05 4002.84,2367.05
							"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3936.2639 2308.6997)"
            className={S.Number}
          >
            125
          </text>
          <text
            transform="matrix(1 0 0 1 3947.8848 2338.0786)"
            className={S.Plan}
          >
            C
          </text>
        </g>
      </g>
      <g className={unitClass(b, "126")} id="UNIT-126-C">
        <polygon
          className={S.Hot}
          points="4002.84,2148.77 3909.23,2148.77 3909.23,2166.41 3900.52,2166.41 3900.52,2256.65 4002.84,2256.65
							"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3935.8354 2199.4395)"
            className={S.Number}
          >
            126
          </text>
          <text
            transform="matrix(1 0 0 1 3947.885 2228.8184)"
            className={S.Plan}
          >
            C
          </text>
        </g>
      </g>
      <g className={unitClass(b, "127")} id="UNIT-127-C2">
        <polygon
          className={S.Hot}
          points="4002.84,2023.63 3952.56,2023.63 3952.56,2037.22 3900.52,2037.22 3900.52,2129.76 3909.23,2129.76
				3909.23,2148.77 4002.84,2148.77 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3936.4468 2090.1797)"
            className={S.Number}
          >
            127
          </text>
          <text
            transform="matrix(1 0 0 1 3940.0989 2119.5586)"
            className={S.Plan}
          >
            C2
          </text>
        </g>
      </g>
    </g>
  );
};

export default Building11;
