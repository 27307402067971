const Club = () => {
  return (
    <g id="TEXT-THE-CARDINAL-CLUB">
      <path
        d="M4673.87,1139.97v21.14h2.98v0.5h-9.25v-0.5h2.98v-21.14h-4.66c-1.58,0-2.01,0.67-2.28,3.12l-0.3,2.65h-0.5
			c0,0,0.2-3.02,0.2-4.16v-2.11h18.36v2.11c0,1.14,0.13,4.16,0.13,4.16h-0.5l-0.24-2.65c-0.2-2.45-0.7-3.12-2.28-3.12H4673.87z"
      />
      <path
        d="M4684.46,1161.11h2.98v-21.14h-2.98v-0.5h9.25v0.5h-2.98v10.25h11.79v-10.25h-2.98v-0.5h9.25v0.5h-2.98v21.14h2.98v0.5
			h-9.25v-0.5h2.98v-10.39h-11.79v10.39h2.98v0.5h-9.25V1161.11z"
      />
      <path
        d="M4711.96,1161.11h2.98v-21.14h-2.98v-0.5h16.85c0,0-0.7,2.65-0.97,6.27h-0.5v-3.62c0-2.08-0.33-2.14-1.51-2.14h-7.61
			v10.45h5.36v-2.95h0.5v6.7h-0.5v-3.25h-5.36v10.19h7.41c1.88,0,2.04-1.78,2.75-6.13h0.54c0,0-0.5,3.05-0.5,6.63h-16.45V1161.11z"
      />
      <path
        d="M4631.16,1181.51c1-0.03,1.31-0.77,1.64-1.84h0.44c0,0-0.64,3.25-0.64,4.69v1.98h-0.5v-1.04c0-3.22-2.61-5.36-6.2-5.36
			c-5.66,0-6.8,5.5-6.8,10.86c0,5.6,1.27,10.76,6.53,10.76c1.61,0,2.98-0.4,4.02-1.07c1.84-1.21,2.78-3.32,2.78-5.26h0.54
			c0,2.48-1.68,6.84-7.34,6.84c-6.06,0-10.12-4.86-10.12-11.43c0-6.63,4.26-11.19,10.39-11.19
			C4628.41,1179.44,4630.12,1180.37,4631.16,1181.51z"
      />
      <path
        d="M4648.61,1201.32h2.35l-3.15-7.57h-7.71l-2.08,5.6c-0.44,1.21,0.27,1.98,1.81,1.98h1v0.5h-6.2v-0.5h0.67
			c1.04,0,1.64-0.5,2.18-1.98l7.41-19.8h0.5l9.05,21.78h1.64v0.5h-7.47V1201.32z M4640.3,1193.24h7.27l-3.85-9.21L4640.3,1193.24z"
      />
      <path
        d="M4676.62,1199.04h0.5c-0.03,2.04-1.04,2.95-2.78,2.95c-3.79,0-4.19-3.72-4.32-5.6c-0.13-1.94-0.47-5.36-3.32-5.36h-2.98
			v10.29h2.95v0.5h-9.21v-0.5h2.98v-21.14h-2.98v-0.5h8.95c5.76,0,7.94,2.31,7.94,5.63c0,2.65-1.64,4.62-5.96,5.43v0.07
			c3.02,0.7,5.23,2.51,5.36,6.1c0.1,2.71,0.13,4.36,1.44,4.36C4676.05,1201.25,4676.62,1200.28,4676.62,1199.04z M4666.63,1190.53
			c3.15,0,4.16-1.68,4.16-5.03c0-3.45-1.11-5.33-4.32-5.33h-2.75v10.35H4666.63z"
      />
      <path
        d="M4679.46,1201.32h2.98v-21.14h-2.98v-0.5h9.65c7.84,0,11.16,4.22,11.16,10.72c0,6.33-3.08,11.43-11.16,11.43h-9.65
			V1201.32z M4685.73,1180.17v21.14h3.45c5.03,0,7.51-3.65,7.51-10.56c0-7.2-2.48-10.59-7.51-10.59H4685.73z"
      />
      <path d="M4703.72,1201.32h2.98v-21.14h-2.98v-0.5h9.25v0.5h-2.98v21.14h2.98v0.5h-9.25V1201.32z" />
      <path
        d="M4719.4,1181.71c-0.7-0.94-1.11-1.54-2.75-1.54h-0.94v-0.5h6.2l12.6,16.79v-14.27c0-1.27-0.54-2.01-2.01-2.01h-1v-0.5
			h6.57v0.5h-1.04c-1.47,0-1.98,0.74-1.98,2.01v19.8h-0.5l-14.61-19.54v16.85c0,1.27,0.5,2.01,1.98,2.01h1.04v0.5h-6.57v-0.5h1
			c1.47,0,2.01-0.74,2.01-2.01V1181.71z"
      />
      <path
        d="M4753.07,1201.32h2.35l-3.15-7.57h-7.71l-2.08,5.6c-0.44,1.21,0.27,1.98,1.81,1.98h1v0.5h-6.2v-0.5h0.67
			c1.04,0,1.64-0.5,2.18-1.98l7.41-19.8h0.5l9.05,21.78h1.64v0.5h-7.47V1201.32z M4744.76,1193.24h7.27l-3.85-9.21L4744.76,1193.24z
			"
      />
      <path
        d="M4768.25,1201.32h7.17c1.88,0,2.04-1.78,2.75-6.13h0.54c0,0-0.5,2.95-0.5,6.63h-16.22v-0.5h2.98v-21.14h-2.98v-0.5h9.25
			v0.5h-2.98V1201.32z"
      />
      <path
        d="M4670.42,1221.72c1-0.03,1.31-0.77,1.64-1.84h0.44c0,0-0.64,3.25-0.64,4.69v1.98h-0.5v-1.04c0-3.22-2.61-5.36-6.2-5.36
			c-5.66,0-6.8,5.5-6.8,10.86c0,5.6,1.27,10.76,6.53,10.76c1.61,0,2.98-0.4,4.02-1.07c1.84-1.21,2.78-3.32,2.78-5.26h0.54
			c0,2.48-1.68,6.84-7.34,6.84c-6.06,0-10.12-4.86-10.12-11.43c0-6.63,4.26-11.19,10.39-11.19
			C4667.67,1219.64,4669.38,1220.58,4670.42,1221.72z"
      />
      <path
        d="M4681.98,1241.52h7.17c1.88,0,2.04-1.78,2.75-6.13h0.54c0,0-0.5,2.95-0.5,6.63h-16.22v-0.5h2.98v-21.14h-2.98v-0.5h9.25
			v0.5h-2.98V1241.52z"
      />
      <path
        d="M4697.25,1220.38h-2.98v-0.5h9.28v0.5h-2.98v12.33c0,6.43,1.47,9.05,6.07,9.05c4.79,0,7.07-3.02,7.07-8.28v-11.09
			c0-1.27-0.54-2.01-2.01-2.01h-1.01v-0.5h6.53v0.5h-1.04c-1.47,0-1.98,0.74-1.98,2.01v11.16c0,4.86-2.08,8.71-7.57,8.71
			c-7.27,0-9.38-3.52-9.38-9.55V1220.38z"
      />
      <path
        d="M4719.94,1241.52h2.98v-21.14h-2.98v-0.5h8.98c5.46,0,7.87,2.45,7.87,5.33c0,2.28-1.67,4.36-5.39,5.29v0.07
			c4.32,0.8,6.43,2.68,6.43,5.5c0,3.22-2.51,5.96-8.54,5.96h-9.35V1241.52z M4728.95,1230.43c2.51,0,4.39-0.97,4.39-4.79
			c0-3.89-1.84-5.26-4.52-5.26h-2.61v10.05H4728.95z M4729.25,1241.52c3.38,0,5.13-2.01,5.13-5.63c0-3.59-1.84-4.96-5.19-4.96h-2.98
			v10.59H4729.25z"
      />
    </g>
  );
};

export default Club;
