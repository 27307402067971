import S from "./SectionNavigation.module.sass";
import { useDispatch, useSelector } from "react-redux";
import R, { I__Page } from "../../constants/routes";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import classNames from "classnames";
import { selectPage, updatePage } from "../../store/condominiumsSlice";
import { T__Building_Type } from "../../constants/buildings";

const SectionNavigation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentPage = useSelector(selectPage);

  const click = (pathname: string, buildingType: T__Building_Type) => {
    const p = _.find(R, { pathname });
    if (p !== undefined) {
      dispatch(updatePage({ page: p }));
      navigate(pathname);
    }
  };

  const style = (page: I__Page) => {
    const classes = [
      S.Button,
      {
        [`${S.Active}`]: currentPage === page,
      },
    ];
    return classNames(classes);
  };

  return (
    <div className={S.SectionNavigation}>
      <div
        onClick={() => click(R.SITEMAP.pathname, "townhome")}
        className={style(R.SITEMAP)}
      >
        Site Plan
      </div>

      <div
        onClick={() =>
          click(R["CONDOMINIUM-KEY-PLAN"].pathname, "condominiums")
        }
        className={style(R["CONDOMINIUM-KEY-PLAN"])}
      >
        Key Plan
      </div>

      <div
        onClick={() =>
          click(R["CONDOMINIUM-PLAN-TYPE"].pathname, "condominiums")
        }
        className={style(R["CONDOMINIUM-PLAN-TYPE"])}
      >
        Plan Type
      </div>
    </div>
  );
};

export default SectionNavigation;
