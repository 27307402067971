import _ from "lodash";
import S from "../SVG.module.sass";

import BUILDINGS, { I__Buildings } from "../../../../constants/buildings";
import { T__Plan_Name } from "../../../../constants/floorplans/interface";

type Props = {
  unitClass: (build: I__Buildings | undefined, unit: string) => string;
  click: (planName: T__Plan_Name) => void;
};

const Building10 = ({ unitClass }: Props) => {
  const b = _.find(BUILDINGS, { title: "Building 10" });
  return (
    <g id="BUILDING-10">
      <rect
        id="BUILDING-10-PATCH"
        x="4425.85"
        y="2012.1"
        className={S.Patch}
        width="32.35"
        height="46.75"
      />
      <g className={unitClass(b, "92")} id="UNIT-92-E">
        <polygon
          className={S.Hot}
          points="4455.49,2058.85 4441.95,2058.85 4441.95,2121.73 4545.85,2121.73 4546.94,2121.73 4577.75,2121.73
				4577.75,2023.91 4529.14,2023.91 4529.14,2015.1 4488.13,2015.1 4488.13,2035.24 4458.2,2035.24 4455.49,2035.24 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4479.9351 2079.0649)"
            className={S.Number}
          >
            92
          </text>
          <text
            transform="matrix(1 0 0 1 4487.7207 2108.4438)"
            className={S.Plan}
          >
            E
          </text>
        </g>
      </g>
      <g className={unitClass(b, "93")} id="UNIT-93-D">
        <polygon
          className={S.Hot}
          points="4441.95,2121.73 4441.95,2193.13 4574.1,2193.13 4574.1,2171.56 4545.85,2171.56 4545.85,2121.73
							"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4479.8701 2151.5396)"
            className={S.Number}
          >
            93
          </text>
          <text
            transform="matrix(1 0 0 1 4486.2642 2180.9185)"
            className={S.Plan}
          >
            D
          </text>
        </g>
      </g>
      <g className={unitClass(b, "94")} id="UNIT-94-D">
        <polygon
          className={S.Hot}
          points="4441.95,2265.73 4545.85,2265.73 4545.85,2212.57 4574.1,2212.57 4574.1,2193.13 4441.95,2193.13
							"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4479.0386 2223.7671)"
            className={S.Number}
          >
            94
          </text>
          <text
            transform="matrix(1 0 0 1 4486.2651 2253.146)"
            className={S.Plan}
          >
            D
          </text>
        </g>
      </g>
      <g className={unitClass(b, "95")} id="UNIT-95-D">
        <polygon
          className={S.Hot}
          points="4441.95,2338.34 4574.1,2338.34 4574.1,2317.84 4545.85,2317.84 4545.85,2265.73 4441.95,2265.73
							"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4479.8052 2296.1182)"
            className={S.Number}
          >
            95
          </text>
          <text
            transform="matrix(1 0 0 1 4486.2646 2325.4976)"
            className={S.Plan}
          >
            D
          </text>
        </g>
      </g>
      <g className={unitClass(b, "96")} id="UNIT-96-D1">
        <polygon
          className={S.Hot}
          points="4441.95,2411.25 4545.85,2411.25 4545.85,2358.85 4574.1,2358.85 4574.1,2338.34 4441.95,2338.34
							"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4479.3765 2368.4697)"
            className={S.Number}
          >
            96
          </text>
          <text
            transform="matrix(1 0 0 1 4481.6113 2397.8486)"
            className={S.Plan}
          >
            D1
          </text>
        </g>
      </g>
      <g className={unitClass(b, "97")} id="UNIT-97-D2">
        <polygon
          className={S.Hot}
          points="4309.8,2411.25 4309.8,2432.22 4338.96,2432.22 4338.96,2483.86 4441.95,2483.86 4441.95,2411.25
				4338.96,2411.25 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4376.3955 2441.6338)"
            className={S.Number}
          >
            97
          </text>
          <text
            transform="matrix(1 0 0 1 4374.8853 2471.0127)"
            className={S.Plan}
          >
            D2
          </text>
        </g>
      </g>
      <g className={unitClass(b, "98")} id="UNIT-98-D">
        <polygon
          className={S.Hot}
          points="4309.8,2338.34 4309.8,2358.85 4338.96,2358.85 4338.96,2411.25 4441.95,2411.25 4441.95,2338.34
							"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4376.1216 2369.2827)"
            className={S.Number}
          >
            98
          </text>
          <text
            transform="matrix(1 0 0 1 4382.6719 2398.6616)"
            className={S.Plan}
          >
            D
          </text>
        </g>
      </g>
      <g className={unitClass(b, "99")} id="UNIT-99-D">
        <polygon
          className={S.Hot}
          points="4441.95,2265.73 4338.96,2265.73 4338.96,2317.84 4309.8,2317.84 4309.8,2338.34 4441.95,2338.34
							"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4375.7842 2296.9312)"
            className={S.Number}
          >
            99
          </text>
          <text
            transform="matrix(1 0 0 1 4382.6724 2326.3101)"
            className={S.Plan}
          >
            D
          </text>
        </g>
      </g>
      <g className={unitClass(b, "100")} id="UNIT-100-D">
        <polygon
          className={S.Hot}
          points="4441.95,2193.13 4309.8,2193.13 4309.8,2212.57 4338.96,2212.57 4338.96,2265.73 4441.95,2265.73
							"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4368.8667 2224.5796)"
            className={S.Number}
          >
            100
          </text>
          <text
            transform="matrix(1 0 0 1 4382.6714 2253.959)"
            className={S.Plan}
          >
            D
          </text>
        </g>
      </g>
      <g className={unitClass(b, "101")} id="UNIT-101-D">
        <polygon
          className={S.Hot}
          points="4441.95,2121.73 4338.96,2121.73 4338.96,2171.56 4309.8,2171.56 4309.8,2193.13 4441.95,2193.13
							"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4373.1304 2152.3521)"
            className={S.Number}
          >
            101
          </text>
          <text
            transform="matrix(1 0 0 1 4382.6714 2181.7314)"
            className={S.Plan}
          >
            D
          </text>
        </g>
      </g>
      <g className={unitClass(b, "102")} id="UNIT-102-E">
        <polygon
          className={S.Hot}
          points="4441.95,2058.85 4425.85,2058.85 4425.85,2035.24 4395.77,2035.24 4395.77,2015.1 4355.67,2015.1
				4355.67,2023.91 4306.15,2023.91 4306.15,2121.73 4338.96,2121.73 4441.95,2121.73 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4370.3105 2079.8779)"
            className={S.Number}
          >
            102
          </text>
          <text
            transform="matrix(1 0 0 1 4384.1284 2109.2568)"
            className={S.Plan}
          >
            E
          </text>
        </g>
      </g>
    </g>
  );
};

export default Building10;
