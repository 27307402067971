import S from "../BuildingsKey.module.sass";
import { Props } from "../Props";

const Level3 = ({ unitStyle, click }: Props) => {
  return (
    <svg viewBox="0 0 480 290" className={S.Keyplate}>
      <g className={S.Lineworks}>
        <polygon points="446.7 113.11 374.87 113.17 374.88 132.1 361.6 132.11 361.52 42.94 420.29 42.89 420.31 58.43 446.65 58.4 446.7 113.11" />
        <rect x="374.89" y="113.16" width="67.56" height="54.04" />
        <polygon points="374.9 148.31 361.61 148.32 361.69 237.5 420.46 237.45 420.45 221.91 446.79 221.89 446.75 167.17 374.91 167.23 374.9 148.31" />
        <polygon points="361.59 132.11 302.14 132.16 302.06 42.99 334.49 42.96 334.5 53.77 361.52 53.75 361.59 132.11" />
        <polygon points="302.14 132.16 267.01 132.19 266.92 51.13 302.07 51.1 302.14 132.16" />
        <rect x="177.79" y="51.19" width="35.12" height="81.07" />
        <polygon points="177.83 132.27 118.39 132.32 118.32 53.96 145.33 53.94 145.33 43.13 177.75 43.1 177.83 132.27" />
        <polygon points="118.38 132.32 105.1 132.34 105.08 113.43 33.25 113.49 33.2 58.76 59.55 58.74 59.54 43.2 118.3 43.15 118.38 132.32" />
        <rect x="37.55" y="113.46" width="67.56" height="54.04" />
        <polygon points="105.12 148.55 118.4 148.54 118.48 237.71 59.71 237.76 59.69 222.22 33.35 222.25 33.3 167.53 105.13 167.46 105.12 148.55" />
        <polygon points="118.47 235 173.21 234.96 173.21 224.16 199.56 224.14 199.49 148.46 140.49 148.51 140.53 194.9 118.44 194.92 118.47 235" />
        <polygon points="361.69 234.79 306.96 234.85 306.95 224.03 280.61 224.06 280.54 148.39 339.54 148.34 339.58 194.73 361.65 194.71 361.69 234.79" />
        <rect x="246.06" y="125.44" width="20.95" height="6.76" />
        <rect x="228.64" y="157.43" width="18.69" height="13.51" />
        <rect x="203.43" y="157.46" width="18.69" height="13.51" />
        <line x1="247.33" y1="170.94" x2="228.63" y2="157.44" />
        <line x1="247.32" y1="157.43" x2="228.64" y2="170.95" />
        <line x1="222.12" y1="157.45" x2="203.44" y2="170.98" />
        <line x1="222.13" y1="170.96" x2="203.43" y2="157.47" />
        <line x1="140.48" y1="164.28" x2="130.56" y2="164.29" />
        <line x1="128.75" y1="164.29" x2="118.42" y2="164.3" />
        <line x1="140.48" y1="166.75" x2="130.56" y2="166.76" />
        <line x1="128.76" y1="166.77" x2="118.42" y2="166.78" />
        <line x1="140.48" y1="169.23" x2="130.56" y2="169.24" />
        <line x1="128.76" y1="169.24" x2="118.42" y2="169.25" />
        <line x1="140.48" y1="171.71" x2="130.56" y2="171.72" />
        <line x1="128.76" y1="171.72" x2="118.42" y2="171.73" />
        <line x1="140.48" y1="174.19" x2="130.56" y2="174.19" />
        <line x1="128.76" y1="174.2" x2="118.43" y2="174.21" />
        <line x1="140.49" y1="176.66" x2="130.57" y2="176.67" />
        <line x1="128.77" y1="176.67" x2="118.43" y2="176.68" />
        <line x1="140.49" y1="179.14" x2="130.57" y2="179.15" />
        <line x1="128.77" y1="179.15" x2="118.43" y2="179.16" />
        <line x1="140.49" y1="181.62" x2="130.57" y2="181.63" />
        <line x1="128.77" y1="181.63" x2="118.43" y2="181.64" />
        <line x1="140.49" y1="184.09" x2="130.57" y2="184.1" />
        <line x1="128.77" y1="184.1" x2="118.43" y2="184.11" />
        <line x1="251.04" y1="153.37" x2="199.5" y2="153.41" />
        <line x1="361.61" y1="148.32" x2="339.53" y2="148.34" />
        <line x1="118.4" y1="148.54" x2="140.49" y2="148.52" />
        <rect x="128.76" y="163.37" width="1.8" height="21.62" />
        <polyline points="33.26 113.49 21.34 113.5 21.39 167.54 33.3 167.53" />
        <polyline points="145.32 43.13 145.32 37.72 118.3 37.75 118.3 43.15" />
        <polyline points="173.22 234.96 173.22 243.06 199.57 243.04 199.57 234.94" />
        <polyline points="229.53 234.9 229.47 252.47 280.55 252.42 280.61 234.87" />
        <polyline points="280.61 234.87 280.62 242.97 306.97 242.95 306.96 234.85" />
        <polyline points="446.7 113.12 458.61 113.11 458.66 167.16 446.75 167.17" />
        <polyline points="361.52 42.94 361.51 37.53 334.48 37.56 334.49 42.96" />
        <polyline points="302.06 42.99 302.06 37.59 266.93 37.62 266.94 51.13" />
        <polyline points="177.75 43.1 177.75 37.69 212.88 37.66 212.89 51.17" />
        <polyline points="59.54 43.2 33.19 43.23 33.2 58.76" />
        <polyline points="33.37 222.25 33.39 237.79 59.71 237.76" />
        <polyline points="420.46 237.45 446.81 237.42 446.77 221.89" />
        <polyline points="446.65 58.4 446.64 42.86 420.29 42.89" />
        <rect x="349.49" y="163.18" width="1.8" height="21.62" />
        <line x1="361.62" y1="164.08" x2="351.28" y2="164.09" />
        <line x1="349.48" y1="164.1" x2="339.55" y2="164.1" />
        <line x1="361.63" y1="166.56" x2="351.28" y2="166.57" />
        <line x1="349.48" y1="166.57" x2="339.55" y2="166.58" />
        <line x1="361.63" y1="169.04" x2="351.28" y2="169.05" />
        <line x1="349.48" y1="169.05" x2="339.55" y2="169.06" />
        <line x1="361.63" y1="171.52" x2="351.29" y2="171.53" />
        <line x1="349.48" y1="171.53" x2="339.56" y2="171.53" />
        <line x1="361.63" y1="173.99" x2="351.29" y2="174" />
        <line x1="349.49" y1="174" x2="339.56" y2="174.01" />
        <line x1="361.64" y1="176.47" x2="351.29" y2="176.48" />
        <line x1="349.49" y1="176.48" x2="339.56" y2="176.49" />
        <line x1="361.64" y1="178.95" x2="351.29" y2="178.96" />
        <line x1="349.49" y1="178.96" x2="339.56" y2="178.97" />
        <line x1="361.64" y1="181.42" x2="351.29" y2="181.43" />
        <line x1="349.49" y1="181.43" x2="339.56" y2="181.44" />
        <line x1="361.64" y1="183.9" x2="351.3" y2="183.91" />
        <line x1="349.49" y1="183.91" x2="339.57" y2="183.92" />
        <polygon points="212.87 51.18 266.94 51.13 267.01 125.44 246.06 125.46 246.07 132.21 212.94 132.24 212.87 51.18" />
        <line x1="266.93" y1="37.62" x2="212.87" y2="37.66" />
        <polygon points="280.54 148.4 280.62 234.87 199.57 234.94 199.51 175.49 251.06 175.44 251.04 148.42 280.54 148.4" />
        <polygon points="446.7 113.11 374.87 113.17 374.89 132.1 361.6 132.11 361.52 42.94 420.3 42.89 420.31 58.43 446.66 58.4 446.7 113.11" />
      </g>
      <g className={S.Units} id="building-6-units">
        <g
          id="UNIT-301-H"
          onClick={() => click("H")}
          className={unitStyle("H", "301")}
        >
          <polygon
            className={S.Hot}
            points="118.48 235 173.21 234.96 173.21 224.16 199.56 224.14 199.49 148.46 140.5 148.51 140.54 194.9 118.44 194.92 118.48 235"
          />
          <g className={S.Label}>
            <text transform="translate(155.72 197.84)">301</text>
            <text transform="translate(163.37 181.61) scale(1.04 1)">H</text>
          </g>
        </g>
        <g
          id="UNIT-302-G"
          onClick={() => click("G")}
          className={unitStyle("G", "302")}
        >
          <polygon
            className={S.Hot}
            points="105.12 148.55 118.41 148.54 118.48 237.71 59.71 237.76 59.7 222.22 33.35 222.25 33.3 167.53 105.13 167.46 105.12 148.55"
          />
          <g className={S.Label}>
            <text transform="translate(65.19 209.86)">302</text>
            <text transform="translate(74.4 193.62) scale(1.04 1)">G</text>
          </g>
        </g>
        <g
          id="UNIT-303-B"
          onClick={() => click("B")}
          className={unitStyle("B", "303")}
        >
          <rect
            className={S.Hot}
            x="37.56"
            y="113.46"
            width="67.56"
            height="54.04"
          />
          <g className={S.Label}>
            <text transform="translate(55.46 154.81)">303</text>
            <text transform="translate(65.23 138.58) scale(1.04 1)">B</text>
          </g>
        </g>
        <g
          id="UNIT-304-G"
          onClick={() => click("G")}
          className={unitStyle("G", "304")}
        >
          <polygon
            className={S.Hot}
            points="118.38 132.32 105.1 132.34 105.09 113.43 33.26 113.49 33.21 58.76 59.55 58.74 59.54 43.2 118.31 43.15 118.38 132.32"
          />
          <g className={S.Label}>
            <text transform="translate(64.63 89.59)">304</text>
            <text transform="translate(74.4 73.36) scale(1.04 1)">G</text>
          </g>
        </g>
        <g
          id="UNIT-305-F"
          onClick={() => click("F")}
          className={unitStyle("F", "305")}
        >
          <polygon
            className={S.Hot}
            points="177.83 132.27 118.39 132.32 118.32 53.96 145.34 53.94 145.33 43.13 177.75 43.1 177.83 132.27"
          />
          <g className={S.Label}>
            <text transform="translate(132.16 96.9)">305</text>
            <text transform="translate(142.53 80.66) scale(1.04 1)">F</text>
          </g>
        </g>
        <g
          id="UNIT-306-A"
          onClick={() => click("A")}
          className={unitStyle("A", "306")}
        >
          <rect
            className={S.Hot}
            x="177.8"
            y="51.19"
            width="35.12"
            height="81.07"
          />
          <g className={S.Label}>
            <text transform="translate(179.74 96.9)">306</text>
            <text transform="translate(189.25 80.66) scale(1.04 1)">A</text>
          </g>
        </g>
        <g
          id="UNIT-307-C"
          onClick={() => click("C")}
          className={unitStyle("C", "307")}
        >
          <polygon
            className={S.Hot}
            points="212.88 51.18 266.94 51.13 267.01 125.44 246.06 125.46 246.07 132.21 212.95 132.24 212.88 51.18"
          />
          <g className={S.Label}>
            <text transform="translate(225.14 96.9)">307</text>
            <text transform="translate(234.71 80.66) scale(1.04 1)">C</text>
          </g>
        </g>
        <g
          id="UNIT-308-A"
          onClick={() => click("A")}
          className={unitStyle("A", "308")}
        >
          <polygon
            className={S.Hot}
            points="302.14 132.16 267.01 132.19 266.93 51.13 302.07 51.1 302.14 132.16"
          />
          <g className={S.Label}>
            <text transform="translate(268.56 96.9)">308</text>
            <text transform="translate(277.86 80.66) scale(1.04 1)">A</text>
          </g>
        </g>
        <g
          id="UNIT-309-F"
          onClick={() => click("F")}
          className={unitStyle("F", "309")}
        >
          <polygon
            className={S.Hot}
            points="361.59 132.11 302.14 132.16 302.06 42.99 334.49 42.96 334.5 53.77 361.52 53.75 361.59 132.11"
          />
          <g className={S.Label}>
            <text transform="translate(315.65 96.9)">309</text>
            <text transform="translate(326.29 80.66) scale(1.04 1)">F</text>
          </g>
        </g>
        <g
          id="UNIT-310-G"
          onClick={() => click("G")}
          className={unitStyle("G", "310")}
        >
          <polygon
            className={S.Hot}
            points="446.7 113.11 374.87 113.17 374.89 132.1 361.6 132.11 361.52 42.94 420.3 42.89 420.31 58.43 446.66 58.4 446.7 113.11"
          />
          <g className={S.Label}>
            <text transform="translate(387.12 89.59)">310</text>
            <text transform="translate(394.57 73.36) scale(1.04 1)">G</text>
          </g>
        </g>
        <g
          id="UNIT-311-B"
          onClick={() => click("B")}
          className={unitStyle("B", "311")}
        >
          <rect
            className={S.Hot}
            x="374.9"
            y="113.16"
            width="67.56"
            height="54.04"
          />
          <g className={S.Label}>
            <text transform="translate(397.25 154.52)">311</text>
            <text transform="translate(402.57 138.28) scale(1.04 1)">B</text>
          </g>
        </g>
        <g
          id="UNIT-312-G"
          onClick={() => click("G")}
          className={unitStyle("G", "312")}
        >
          <polygon
            className={S.Hot}
            points="374.9 148.31 361.61 148.32 361.69 237.5 420.47 237.45 420.45 221.91 446.8 221.89 446.75 167.17 374.92 167.23 374.9 148.31"
          />
          <g className={S.Label}>
            <text transform="translate(388.01 209.86)">312</text>
            <text transform="translate(394.57 193.62) scale(1.04 1)">G</text>
          </g>
        </g>
        <g
          id="UNIT-313-H"
          onClick={() => click("H")}
          className={unitStyle("H", "313")}
        >
          <polygon
            className={S.Hot}
            points="361.69 234.79 306.97 234.85 306.95 224.03 280.61 224.06 280.54 148.39 339.54 148.34 339.58 194.73 361.65 194.71 361.69 234.79"
          />
          <g className={S.Label}>
            <text transform="translate(297.9 196.28)">313</text>
            <text transform="translate(304.69 180.04) scale(1.04 1)">H</text>
          </g>
        </g>
        <g
          id="UNIT-314-E"
          onClick={() => click("E")}
          className={unitStyle("E", "314")}
        >
          <polygon
            className={S.Hot}
            points="280.54 148.4 280.63 234.87 199.57 234.94 199.52 175.49 251.06 175.44 251.04 148.42 280.54 148.4"
          />
          <g className={S.Label}>
            <text transform="translate(227.21 214.95)">314</text>
            <text transform="translate(235.28 198.71) scale(1.04 1)">E</text>
          </g>
        </g>
      </g>
      <g className={S.LevelLabel}>
        <text transform="matrix(1 0 0 1 380.5 281.6378)">Level 3</text>
      </g>
    </svg>
  );
};

export default Level3;
