import _ from "lodash";
import S from "../SVG.module.sass";

import BUILDINGS, { I__Buildings } from "../../../../constants/buildings";
import { T__Plan_Name } from "../../../../constants/floorplans/interface";

type Props = {
  unitClass: (build: I__Buildings | undefined, unit: string) => string;
  click: (planName: T__Plan_Name) => void;
};

const Building7 = ({ unitClass }: Props) => {
  const b = _.find(BUILDINGS, { title: "Building 7" });
  return (
    <g id="BUILDING-7">
      <g className={unitClass(b, "48")} id="UNIT-48-G1">
        <polygon
          className={S.Hot}
          points="5868.931,1948.177 5951.07,1948.177 5951.07,1926.304 5976.475,1926.304 5976.475,1916.734
				5962.12,1916.734 5962.12,1874.468 5909.032,1874.468 5909.032,1845.418 5859.019,1845.418 5859.019,1874.468 5776.424,1874.468
				5776.424,1929.494 5868.931,1929.494 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 5868.3418 1893.8804)"
            className={S.Number}
          >
            48
          </text>
          <text
            transform="matrix(1 0 0 1 5870.5508 1923.2594)"
            className={S.Plan}
          >
            G1
          </text>
        </g>
      </g>
      <g className={unitClass(b, "49")} id="UNIT-49-F">
        <polygon
          className={S.Hot}
          points="5868.931,1948.177 5868.931,1929.494 5776.424,1929.494 5776.424,2019.266 5959.842,2019.266
				5962.804,2019.266 5962.804,1967.317 5951.07,1967.317 5951.07,1948.177 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 5868.0039 1977.2738)"
            className={S.Number}
          >
            49
          </text>
          <text
            transform="matrix(1 0 0 1 5876.8677 2006.6528)"
            className={S.Plan}
          >
            F
          </text>
        </g>
      </g>
      <g className={unitClass(b, "50")} id="UNIT-50-G">
        <polygon
          className={S.Hot}
          points="5776.424,2072.127 5868.133,2072.127 5868.133,2090.81 5951.07,2090.81 5951.07,2070.304
				5976.475,2070.304 5976.475,2060.051 5959.842,2060.051 5959.842,2019.266 5776.424,2019.266 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 5867.8867 2051.4517)"
            className={S.Number}
          >
            50
          </text>
          <text
            transform="matrix(1 0 0 1 5875.2041 2080.8308)"
            className={S.Plan}
          >
            G
          </text>
        </g>
      </g>
      <g className={unitClass(b, "51")} id="UNIT-51-F">
        <polygon
          className={S.Hot}
          points="5868.133,2072.127 5776.424,2072.127 5776.424,2161.671 5959.842,2161.671 5962.576,2161.671
				5962.576,2110.177 5951.07,2110.177 5951.07,2090.81 5868.133,2090.81 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 5872.1509 2124.2732)"
            className={S.Number}
          >
            51
          </text>
          <text
            transform="matrix(1 0 0 1 5876.8682 2153.6521)"
            className={S.Plan}
          >
            F
          </text>
        </g>
      </g>
      <g className={unitClass(b, "52")} id="UNIT-52-G">
        <polygon
          className={S.Hot}
          points="5776.424,2215.671 5868.133,2215.671 5868.133,2233.899 5951.07,2233.899 5951.07,2212.709
				5976.475,2212.709 5976.475,2203.595 5959.842,2203.595 5959.842,2161.671 5776.424,2161.671 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 5869.3296 2195.0059)"
            className={S.Number}
          >
            52
          </text>
          <text
            transform="matrix(1 0 0 1 5875.2031 2224.3848)"
            className={S.Plan}
          >
            G
          </text>
        </g>
      </g>
      <g className={unitClass(b, "53")} id="UNIT-53-F">
        <polygon
          className={S.Hot}
          points="5868.133,2215.671 5776.424,2215.671 5776.424,2305.443 5959.158,2305.443 5962.576,2305.443
				5962.576,2253.494 5951.07,2253.494 5951.07,2233.899 5868.133,2233.899 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 5869.2651 2265.3354)"
            className={S.Number}
          >
            53
          </text>
          <text
            transform="matrix(1 0 0 1 5876.8682 2294.7146)"
            className={S.Plan}
          >
            F
          </text>
        </g>
      </g>
      <g className={unitClass(b, "54")} id="UNIT-54-G">
        <polygon
          className={S.Hot}
          points="5776.424,2358.304 5868.133,2358.304 5868.133,2377.899 5951.07,2377.899 5951.07,2356.937
				5976.475,2356.937 5976.475,2347.595 5959.158,2347.595 5959.158,2305.443 5776.424,2305.443 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 5868.4326 2339.6953)"
            className={S.Number}
          >
            54
          </text>
          <text
            transform="matrix(1 0 0 1 5875.2036 2369.0742)"
            className={S.Plan}
          >
            G
          </text>
        </g>
      </g>
      <g className={unitClass(b, "55")} id="UNIT-55-F2">
        <polygon
          className={S.Hot}
          points="5868.133,2358.304 5776.424,2358.304 5776.424,2450.127 5897.412,2450.127 5897.412,2463.342
				5908.576,2463.342 5908.576,2453.772 5967.133,2453.772 5967.133,2396.81 5951.07,2396.81 5951.07,2377.899 5868.133,2377.899
							"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 5869.2007 2406.1545)"
            className={S.Number}
          >
            55
          </text>
          <text
            transform="matrix(1 0 0 1 5869.082 2435.5334)"
            className={S.Plan}
          >
            F2
          </text>
        </g>
      </g>
    </g>
  );
};

export default Building7;
