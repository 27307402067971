import S from "./Sitemap.module.sass";
import bgImage from "../../../images/siteplan-v5.jpg";
import SVG from "../SVG";
import { setTransform } from "@pronestor/react-zoom-pan-pinch/dist/core/handlers/handlers.logic";

type Props = {
  setTransform: ReturnType<typeof setTransform>;
};
const Sitemap = ({ setTransform }: Props) => {
  // const size = useWindowSize();
  //
  // useEffect(() => {
  //   if (size !== undefined) {
  //     // console.log("setTransform", size);
  //     // @ts-ignore
  //     setTransform(size?.width * -0.4, size?.height * -0.225, 2);
  //   }
  // }, [size]);

  return (
    <div className={S.Sitemap}>
      <img src={bgImage} alt="highstreet site map" />
      <div className={S.SVGContainer}>
        <SVG />
      </div>
    </div>
  );
};

export default Sitemap;
