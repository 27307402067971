import { I__Plan } from "../interface";

const Building4: Array<I__Plan> = [
  {
    title: "A",
    type: "condominiums",
    condoBuilding: "Building 6",
    bedroom: "studio",
    bath: 1,
    area: [390],
  },
  {
    title: "B",
    type: "condominiums",
    condoBuilding: "Building 6",
    bedroom: 1,
    bath: 1,
    area: [499],
  },
  {
    title: "C",
    type: "condominiums",
    condoBuilding: "Building 6",
    bedroom: 1,
    bath: 1,
    additional: "den",
    area: [581],
  },
  // {
  //   title: "C1",
  //   type: "condominiums",
  //   condoBuilding: "Building 6",
  //   bedroom: 1,
  //   bath: 1,
  //   additional: "den",
  //   area: [638],
  // },
  {
    title: "D",
    type: "condominiums",
    condoBuilding: "Building 6",
    bedroom: 2,
    bath: 2,
    area: [712, 719],
  },
  {
    title: "E",
    type: "condominiums",
    condoBuilding: "Building 6",
    bedroom: 2,
    bath: 2,
    area: [762],
  },
  {
    title: "F",
    type: "condominiums",
    condoBuilding: "Building 6",
    bedroom: 2,
    bath: 2,
    area: [686],
  },
  {
    title: "G",
    type: "condominiums",
    condoBuilding: "Building 6",
    bedroom: 2,
    bath: 2,
    area: [798],
  },
  {
    title: "H",
    type: "condominiums",
    condoBuilding: "Building 6",
    bedroom: 2,
    bath: 2,
    additional: "den",
    area: [773, 781],
  },
];

export default Building4;
