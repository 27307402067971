import classNames from "classnames";
import _ from "lodash";
import { useSelector } from "react-redux";
import FLOORPLANS from "../../constants/floorplans";
import SVGS from "./BuildingsKey.module.sass";
import S from "./Buildings.module.sass";
import CompassSymbol from "../../images/compass.svg";
import Level1 from "./Building6/Level1";
import Level2 from "./Building6/Level2";
import Level3 from "./Building6/Level3";
import Level4 from "./Building6/Level4";
import Level5 from "./Building6/Level5";
import Level6 from "./Building6/Level6";
import { I__Plan, T__Plan_Name } from "../../constants/floorplans/interface";
import { selectUnits } from "../../store/condominiumsSlice";
import { I__Unit } from "../../store/I__InitialState";

export type Props = {
  click: (plan: T__Plan_Name) => void;
};
const Building6 = ({ click }: Props) => {
  const UNITS = useSelector(selectUnits);
  const unitStyle = (plan: T__Plan_Name, unit: string) => {
    const u: I__Unit = _.find(UNITS, {
      unit,
      "building type": "Condominiums",
      building: "Building 6",
    });
    // console.log("u=", u);
    // @ts-ignore
    const p: I__Plan | undefined = _.find(FLOORPLANS, {
      title: plan,
      type: "condominiums",
      condoBuilding: "Building 6",
    });
    const classes = [
      SVGS.Unit,
      {
        [`${SVGS.Studio}`]: p?.bedroom === "studio",
        [`${SVGS.Plan1Bed1Bath}`]: p?.bedroom === 1,
        [`${SVGS.Plan1BedDen1Bath}`]:
          p?.bedroom === 1 && p?.additional === "den",
        [`${SVGS.Plan2Bed2Bath}`]: p?.bedroom === 2,
        [`${SVGS.Plan2BedDen2Bath}`]:
          p?.bedroom === 2 && p?.additional === "den",
        [`${SVGS.Plan3Bed2Bath}`]: p?.bedroom === 3,
        [`${SVGS.Hold}`]: u?.status === "hold",
        [`${SVGS.Sold}`]: u?.status === "sold",
        [`${SVGS.Disable}`]: u?.status === "disable",
      },
    ];
    return classNames(classes);
  };

  return (
    <>
      <div className={S.Compass}>
        <img src={CompassSymbol} alt="compass" />
      </div>
      <div className={S.KeyplatesContainer}>
        {/*<div className={S.Heading}>*/}
        {/*  <h2>Building 4</h2>*/}
        {/*</div>*/}
        <div className={S.Grid}>
          <div className={S.Box}>
            <Level1 unitStyle={unitStyle} click={click} />
          </div>
          <div className={S.Box}>
            <Level2 unitStyle={unitStyle} click={click} />
          </div>
          <div className={S.Box}>
            <Level3 unitStyle={unitStyle} click={click} />
          </div>
          <div className={S.Box}>
            <Level4 unitStyle={unitStyle} click={click} />
          </div>
          <div className={S.Box}>
            <Level5 unitStyle={unitStyle} click={click} />
          </div>
          <div className={S.Box}>
            <Level6 unitStyle={unitStyle} click={click} />
          </div>
        </div>
        <div className={S.Legend}>
          <div className={classNames(S.Element, S.PlanStudio)}>
            <div className={S.ColorBox} />
            <span>Studio</span>
          </div>

          <div className={classNames(S.Element, S.Plan1Bed1Bath)}>
            <div className={S.ColorBox} />
            <span>1 Bedroom + 1 Bath</span>
          </div>

          <div className={classNames(S.Element, S.Plan1BedDen1Bath)}>
            <div className={S.ColorBox} />
            <span>1 Bedroom + Den</span>
          </div>

          <div className={classNames(S.Element, S.Plan2Bed2Bath)}>
            <div className={S.ColorBox} />
            <span>2 Bedroom + 2 Bath</span>
          </div>

          <div className={classNames(S.Element, S.Plan2BedDen2Bath)}>
            <div className={S.ColorBox} />
            <span>2 Bedroom + Den</span>
          </div>

          {/*<div className={classNames(S.Element, S.Hold)}>*/}
          {/*	<div className={S.ColorBox} />*/}
          {/*	<span>Hold</span>*/}
          {/*</div>*/}

          <div className={classNames(S.Element, S.Sold)}>
            <div className={S.ColorBox} />
            <span>Sold</span>
          </div>

          {/*<div className={classNames(S.Element, S.Disable)}>*/}
          {/*	<div className={S.ColorBox} />*/}
          {/*	<span>Disable</span>*/}
          {/*</div>*/}
        </div>
      </div>
    </>
  );
};

export default Building6;
