import S from "../BuildingsKey.module.sass";
import { Props } from "../Props";

const Level2 = ({ unitStyle, click }: Props) => {
  return (
    <svg viewBox="0 0 480 290" className={S.Keyplate}>
      <g className={S.Lineworks}>
        <polygon points="446.7 109.68 374.87 109.74 374.88 128.67 361.6 128.69 361.52 39.51 420.29 39.46 420.31 55 446.65 54.98 446.7 109.68" />
        <rect x="374.89" y="109.74" width="67.56" height="54.04" />
        <polygon points="374.9 144.89 361.61 144.9 361.69 234.07 420.46 234.02 420.45 218.48 446.79 218.46 446.75 163.74 374.91 163.8 374.9 144.89" />
        <polygon points="361.59 128.69 302.14 128.73 302.06 39.56 334.49 39.54 334.5 50.34 361.52 50.32 361.59 128.69" />
        <polygon points="302.14 128.73 267.01 128.76 266.92 47.7 302.07 47.67 302.14 128.73" />
        <rect x="177.79" y="47.76" width="36.25" height="81.07" />
        <polygon points="177.83 128.85 118.39 128.9 118.32 50.54 145.33 50.51 145.33 39.7 177.75 39.67 177.83 128.85" />
        <polygon points="118.38 128.9 105.1 128.91 105.08 110 33.25 110.06 33.2 55.34 59.55 55.31 59.54 39.78 118.3 39.72 118.38 128.9" />
        <rect x="37.55" y="110.03" width="67.56" height="54.04" />
        <polygon points="105.12 145.12 118.4 145.11 118.48 234.29 59.71 234.34 59.69 218.8 33.35 218.82 33.3 164.1 105.13 164.04 105.12 145.12" />
        <polygon points="129.87 231.57 173.21 231.53 173.21 220.73 199.56 220.71 199.49 145.04 140.49 145.09 140.53 191.48 129.72 191.49 129.87 231.57" />
        <polygon points="350.42 231.38 306.96 231.42 306.95 220.61 280.61 220.63 280.54 144.97 339.54 144.92 339.58 191.31 350.39 191.3 350.42 231.38" />
        <rect x="246.06" y="122.02" width="20.95" height="6.76" />
        <rect x="228.64" y="154.01" width="18.69" height="13.51" />
        <rect x="203.43" y="154.03" width="18.69" height="13.51" />
        <line x1="247.33" y1="167.51" x2="228.63" y2="154.02" />
        <line x1="247.32" y1="154" x2="228.64" y2="167.53" />
        <line x1="222.12" y1="154.02" x2="203.44" y2="167.55" />
        <line x1="222.13" y1="167.53" x2="203.43" y2="154.04" />
        <line x1="140.48" y1="160.85" x2="130.56" y2="160.86" />
        <line x1="128.75" y1="160.86" x2="118.42" y2="160.87" />
        <line x1="140.48" y1="163.33" x2="130.56" y2="163.34" />
        <line x1="128.76" y1="163.34" x2="118.42" y2="163.35" />
        <line x1="140.48" y1="165.81" x2="130.56" y2="165.82" />
        <line x1="128.76" y1="165.82" x2="118.42" y2="165.83" />
        <line x1="140.48" y1="168.28" x2="130.56" y2="168.29" />
        <line x1="128.76" y1="168.29" x2="118.42" y2="168.3" />
        <line x1="140.48" y1="170.76" x2="130.56" y2="170.77" />
        <line x1="128.76" y1="170.77" x2="118.43" y2="170.78" />
        <line x1="140.49" y1="173.24" x2="130.57" y2="173.25" />
        <line x1="128.77" y1="173.25" x2="118.43" y2="173.26" />
        <line x1="140.49" y1="175.72" x2="130.57" y2="175.72" />
        <line x1="128.77" y1="175.73" x2="118.43" y2="175.74" />
        <line x1="140.49" y1="178.19" x2="130.57" y2="178.2" />
        <line x1="128.77" y1="178.2" x2="118.43" y2="178.21" />
        <line x1="140.49" y1="180.67" x2="130.57" y2="180.68" />
        <line x1="128.77" y1="180.68" x2="118.43" y2="180.69" />
        <line x1="251.04" y1="149.94" x2="199.5" y2="149.99" />
        <line x1="361.61" y1="144.9" x2="339.53" y2="144.92" />
        <line x1="118.4" y1="145.11" x2="140.49" y2="145.09" />
        <rect x="128.76" y="159.95" width="1.8" height="21.62" />
        <line x1="129.87" y1="231.57" x2="118.47" y2="231.58" />
        <polyline points="33.26 110.07 21.34 110.07 21.39 164.11 33.3 164.1" />
        <polyline points="145.32 39.7 145.32 34.3 118.3 34.32 118.3 39.73" />
        <polyline points="173.22 231.53 173.22 239.64 199.57 239.62 199.57 231.51" />
        <polyline points="280.61 231.44 280.62 239.55 306.97 239.53 306.96 231.42" />
        <line x1="361.69" y1="231.37" x2="350.43" y2="231.38" />
        <polyline points="446.7 109.7 458.61 109.68 458.66 163.73 446.75 163.74" />
        <polyline points="361.52 39.51 361.51 34.11 334.48 34.13 334.49 39.54" />
        <polyline points="302.06 39.56 302.06 34.16 266.93 34.19 266.94 47.7" />
        <polyline points="177.75 39.67 177.75 34.27 212.88 34.24 212.89 47.75" />
        <polyline points="59.54 39.78 33.19 39.8 33.2 55.34" />
        <polyline points="33.37 218.82 33.39 234.36 59.71 234.34" />
        <polyline points="420.46 234.02 446.81 234 446.77 218.46" />
        <polyline points="446.65 54.98 446.64 39.44 420.29 39.46" />
        <rect x="349.49" y="159.75" width="1.8" height="21.62" />
        <line x1="361.62" y1="160.66" x2="351.28" y2="160.67" />
        <line x1="349.48" y1="160.67" x2="339.55" y2="160.68" />
        <line x1="361.63" y1="163.14" x2="351.28" y2="163.15" />
        <line x1="349.48" y1="163.15" x2="339.55" y2="163.16" />
        <line x1="361.63" y1="165.61" x2="351.28" y2="165.62" />
        <line x1="349.48" y1="165.62" x2="339.55" y2="165.63" />
        <line x1="361.63" y1="168.09" x2="351.29" y2="168.1" />
        <line x1="349.48" y1="168.1" x2="339.56" y2="168.11" />
        <line x1="361.63" y1="170.57" x2="351.29" y2="170.58" />
        <line x1="349.49" y1="170.58" x2="339.56" y2="170.59" />
        <line x1="361.64" y1="173.05" x2="351.29" y2="173.06" />
        <line x1="349.49" y1="173.06" x2="339.56" y2="173.06" />
        <line x1="361.64" y1="175.52" x2="351.29" y2="175.53" />
        <line x1="349.49" y1="175.53" x2="339.56" y2="175.54" />
        <line x1="361.64" y1="178" x2="351.29" y2="178.01" />
        <line x1="349.49" y1="178.01" x2="339.56" y2="178.02" />
        <line x1="361.64" y1="180.48" x2="351.3" y2="180.49" />
        <line x1="349.49" y1="180.49" x2="339.57" y2="180.49" />
        <line x1="350.38" y1="191.3" x2="361.65" y2="191.29" />
        <line x1="361.65" y1="191.29" x2="350.43" y2="231.38" />
        <line x1="361.69" y1="231.37" x2="350.38" y2="191.3" />
        <polygon points="214.01 47.75 266.94 47.7 267.01 122.01 246.06 122.03 246.07 128.78 214.08 128.81 214.01 47.75" />
        <line x1="266.93" y1="34.19" x2="212.87" y2="34.24" />
        <line x1="129.72" y1="191.49" x2="118.44" y2="191.5" />
        <line x1="129.72" y1="191.49" x2="118.47" y2="231.58" />
        <line x1="129.87" y1="231.57" x2="118.44" y2="191.5" />
        <polygon points="280.54 144.97 280.62 231.44 199.57 231.51 199.51 172.07 251.06 172.02 251.04 145 280.54 144.97" />
        <polyline points="229.53 231.47 229.55 255.89 280.63 255.85 280.61 231.44" />
      </g>
      <g id="building-6-units-level-2" className={S.Units}>
        <g
          id="UNIT-201-D"
          onClick={() => click("D")}
          className={unitStyle("D", "201")}
        >
          <polygon
            className={S.Hot}
            points="129.87 231.57 173.21 231.53 173.21 220.73 199.56 220.71 199.49 145.04 140.49 145.09 140.54 191.48 129.73 191.49 129.87 231.57"
          />
          <g className={S.Label}>
            <text transform="translate(155.76 197.84)">201</text>
            <text transform="translate(163.19 181.61) scale(1.04 1)">D</text>
          </g>
        </g>
        <g
          id="UNIT-202-G"
          onClick={() => click("G")}
          className={unitStyle("G", "202")}
        >
          <polygon
            className={S.Hot}
            points="105.12 145.12 118.41 145.11 118.48 234.29 59.71 234.34 59.7 218.8 33.35 218.82 33.3 164.1 105.13 164.04 105.12 145.12"
          />
          <g className={S.Label}>
            <text transform="translate(65.23 209.86)">202</text>
            <text transform="translate(74.4 193.62) scale(1.04 1)">G</text>
          </g>
        </g>
        <g
          id="UNIT-203-B"
          onClick={() => click("B")}
          className={unitStyle("B", "203")}
        >
          <rect
            className={S.Hot}
            x="37.56"
            y="110.03"
            width="67.56"
            height="54.04"
          />
          <g className={S.Label}>
            <text transform="translate(55.5 149.81)">203</text>
            <text transform="translate(65.23 133.58) scale(1.04 1)">B</text>
          </g>
        </g>
        <g
          id="UNIT-204-G"
          onClick={() => click("G")}
          className={unitStyle("G", "204")}
        >
          <polygon
            className={S.Hot}
            points="118.38 128.9 105.1 128.91 105.09 110 33.26 110.06 33.21 55.34 59.55 55.31 59.54 39.78 118.31 39.72 118.38 128.9"
          />
          <g className={S.Label}>
            <text transform="translate(64.67 89.59)">204</text>
            <text transform="translate(74.4 73.36) scale(1.04 1)">G</text>
          </g>
        </g>
        <g
          id="UNIT-205-F"
          onClick={() => click("F")}
          className={unitStyle("F", "205")}
        >
          <polygon
            className={S.Hot}
            points="177.83 128.85 118.39 128.9 118.32 50.54 145.34 50.51 145.33 39.7 177.75 39.67 177.83 128.85"
          />
          <g className={S.Label}>
            <text transform="translate(132.2 96.9)">205</text>
            <text transform="translate(142.53 80.66) scale(1.04 1)">F</text>
          </g>
        </g>
        <g
          id="UNIT-206-A"
          onClick={() => click("A")}
          className={unitStyle("A", "206")}
        >
          <rect
            className={S.Hot}
            x="177.8"
            y="47.76"
            width="36.25"
            height="81.07"
          />
          <g className={S.Label}>
            <text transform="translate(179.78 96.9)">206</text>
            <text transform="translate(189.25 80.66) scale(1.04 1)">A</text>
          </g>
        </g>
        <g
          id="UNIT-207-C"
          onClick={() => click("C")}
          className={unitStyle("C", "207")}
        >
          <polygon
            className={S.Hot}
            points="213.41 47.75 266.33 47.7 266.4 122.01 245.45 122.03 245.46 128.78 213.48 128.81 213.41 47.75"
          />
          <g className={S.Label}>
            <text transform="translate(225.18 96.9)">207</text>
            <text transform="translate(234.71 80.66) scale(1.04 1)">C</text>
          </g>
        </g>
        <g
          id="UNIT-208-A"
          onClick={() => click("A")}
          className={unitStyle("A", "208")}
        >
          <polygon
            className={S.Hot}
            points="302.14 128.73 267.01 128.76 266.93 47.7 302.07 47.67 302.14 128.73"
          />
          <g className={S.Label}>
            <text transform="translate(268.6 96.9)">208</text>
            <text transform="translate(277.86 80.66) scale(1.04 1)">A</text>
          </g>
        </g>
        <g
          id="UNIT-209-F"
          onClick={() => click("F")}
          className={unitStyle("F", "209")}
        >
          <polygon
            className={S.Hot}
            points="361.59 128.69 302.14 128.73 302.07 39.56 334.49 39.54 334.5 50.34 361.52 50.32 361.59 128.69"
          />
          <g className={S.Label}>
            <text transform="translate(315.69 96.9)">209</text>
            <text transform="translate(326.29 80.66) scale(1.04 1)">F</text>
          </g>
        </g>
        <g
          id="UNIT-210-G"
          onClick={() => click("G")}
          className={unitStyle("G", "210")}
        >
          <polygon
            className={S.Hot}
            points="446.7 109.68 374.87 109.74 374.89 128.67 361.6 128.69 361.52 39.51 420.3 39.46 420.31 55 446.66 54.98 446.7 109.68"
          />
          <g className={S.Label}>
            <text transform="translate(387.16 89.59)">210</text>
            <text transform="translate(394.57 73.36) scale(1.04 1)">G</text>
          </g>
        </g>
        <g
          id="UNIT-211-B"
          onClick={() => click("B")}
          className={unitStyle("B", "212")}
        >
          <rect
            className={S.Hot}
            x="374.9"
            y="109.74"
            width="67.56"
            height="54.04"
          />
          <g className={S.Label}>
            <text transform="translate(397.29 149.52)">211</text>
            <text transform="translate(402.57 133.28) scale(1.04 1)">B</text>
          </g>
        </g>
        <g
          id="UNIT-212-G"
          onClick={() => click("G")}
          className={unitStyle("G", "212")}
        >
          <polygon
            className={S.Hot}
            points="374.9 144.89 361.61 144.9 361.69 234.07 420.47 234.02 420.45 218.48 446.8 218.46 446.75 163.74 374.92 163.8 374.9 144.89"
          />
          <g className={S.Label}>
            <text transform="translate(388.06 209.86)">212</text>
            <text transform="translate(394.57 193.62) scale(1.04 1)">G</text>
          </g>
        </g>
        <g
          id="UNIT-213-D"
          onClick={() => click("D")}
          className={unitStyle("D", "213")}
        >
          <polygon
            className={S.Hot}
            points="350.42 231.38 306.97 231.42 306.96 220.61 280.62 220.63 280.54 144.97 339.54 144.92 339.58 191.31 350.39 191.3 350.42 231.38"
          />
          <g className={S.Label}>
            <text transform="translate(297.94 196.28)">213</text>
            <text transform="translate(304.51 180.04) scale(1.04 1)">D</text>
          </g>
        </g>
        <g
          id="UNIT-214-E"
          onClick={() => click("E")}
          className={unitStyle("E", "214")}
        >
          <polygon
            className={S.Hot}
            points="280.54 144.97 280.63 231.44 199.57 231.51 199.52 172.07 251.06 172.02 251.04 145 280.54 144.97"
          />
          <g className={S.Label}>
            <text transform="translate(227.25 214.95)">214</text>
            <text transform="translate(235.28 198.71) scale(1.04 1)">E</text>
          </g>
        </g>
      </g>
      <g className={S.LevelLabel}>
        <text transform="matrix(1 0 0 1 380.5 280.8223)">Level 2</text>
      </g>
    </svg>
  );
};

export default Level2;
