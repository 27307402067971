import _ from "lodash";
import S from "../SVG.module.sass";

import BUILDINGS, { I__Buildings } from "../../../../constants/buildings";
import { T__Plan_Name } from "../../../../constants/floorplans/interface";

type Props = {
  unitClass: (build: I__Buildings | undefined, unit: string) => string;
  click: (planName: T__Plan_Name) => void;
};

const Building6 = ({ unitClass }: Props) => {
  const b = _.find(BUILDINGS, { title: "Building 6" });
  return (
    <g id="BUILDING-6">
      <g className={unitClass(b, "41")} id="UNIT-41-A2">
        <polygon
          className={S.Hot}
          points="5937.437,1237.215 5937.437,1196.582 5928.095,1196.582 5928.095,1145.696 5794.083,1145.696
				5794.083,1237.215 5928.095,1237.215 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 5852.6221 1187.3242)"
            className={S.Number}
          >
            41
          </text>
          <text
            transform="matrix(1 0 0 1 5848.5776 1216.7032)"
            className={S.Plan}
          >
            A2
          </text>
        </g>
      </g>
      <g className={unitClass(b, "42")} id="UNIT-42-Aa">
        <polygon
          className={S.Hot}
          points="5937.437,1287.57 5928.095,1287.57 5928.095,1237.215 5794.083,1237.215 5792.07,1237.215
				5792.07,1328.203 5818.5,1328.203 5937.437,1328.203 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 5849.8008 1277.8135)"
            className={S.Number}
          >
            42
          </text>
          <text
            transform="matrix(1 0 0 1 5848.8374 1307.1925)"
            className={S.Plan}
          >
            Aa
          </text>
        </g>
      </g>
      <g className={unitClass(b, "43")} id="UNIT-43-A">
        <polygon
          className={S.Hot}
          points="5960.374,1419.266 5960.374,1378.709 5951.158,1378.709 5951.158,1328.203 5937.437,1328.203
				5818.5,1328.203 5818.5,1419.266 5951.158,1419.266 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 5866.1812 1369.528)"
            className={S.Number}
          >
            43
          </text>
          <text
            transform="matrix(1 0 0 1 5872.8101 1398.907)"
            className={S.Plan}
          >
            A
          </text>
        </g>
      </g>
      <g className={unitClass(b, "44")} id="UNIT-44-Aa">
        <polygon
          className={S.Hot}
          points="5960.374,1510.405 5960.374,1469.696 5951.158,1469.696 5951.158,1419.266 5818.5,1419.266
				5814.399,1419.266 5814.399,1510.405 5817.741,1510.405 5951.158,1510.405 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 5865.3486 1458.7728)"
            className={S.Number}
          >
            44
          </text>
          <text
            transform="matrix(1 0 0 1 5865.2817 1488.1519)"
            className={S.Plan}
          >
            Aa
          </text>
        </g>
      </g>
      <g className={unitClass(b, "45")} id="UNIT-45-A">
        <polygon
          className={S.Hot}
          points="5960.374,1560.835 5951.158,1560.835 5951.158,1510.405 5817.741,1510.405 5817.741,1601.468
				5837.335,1601.468 5960.374,1601.468 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 5870.4219 1551.5968)"
            className={S.Number}
          >
            45
          </text>
          <text
            transform="matrix(1 0 0 1 5877.1157 1580.9758)"
            className={S.Plan}
          >
            A
          </text>
        </g>
      </g>
      <g className={unitClass(b, "46")} id="UNIT-46-Aa">
        <polygon
          className={S.Hot}
          points="5983.462,1692.532 5983.462,1651.671 5973.816,1651.671 5973.816,1601.468 5960.374,1601.468
				5837.335,1601.468 5837.335,1692.532 5839.766,1692.532 5973.816,1692.532 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 5890.3379 1642.9788)"
            className={S.Number}
          >
            46
          </text>
          <text
            transform="matrix(1 0 0 1 5889.9336 1672.3578)"
            className={S.Plan}
          >
            Aa
          </text>
        </g>
      </g>
      <g className={unitClass(b, "47")} id="UNIT-47-A1">
        <polygon
          className={S.Hot}
          points="5839.766,1692.532 5839.766,1783.519 5983.462,1783.519 5983.462,1742.203 5973.816,1742.203
				5973.816,1692.532 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 5893.6167 1730.3611)"
            className={S.Number}
          >
            47
          </text>
          <text
            transform="matrix(1 0 0 1 5895.4746 1759.7401)"
            className={S.Plan}
          >
            A1
          </text>
        </g>
      </g>
    </g>
  );
};

export default Building6;
