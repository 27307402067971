import S from "../SVG.module.sass";

const MyComponent = () => {
  return (
    <g id="OUTLINE" className={S.Outline}>
      <polygon
        points="4033.44,613.48 4033.44,675.51 4063.06,675.51 4063.06,725.86 4033.44,725.86 4033.44,799.46
		3889.78,799.46 3889.78,848.22 3746.24,848.22 3746.24,896.75 3603.15,896.75 3603.15,775.31 3589.14,775.31 3589.14,763.91
		3603.15,763.91 3603.15,710.6 3654.99,710.6 3654.99,721.08 3694.86,721.08 3694.86,696.01 3704.88,696.01 3704.88,712.19
		3746.24,712.19 3746.24,662.29 3797.39,662.29 3797.39,672.55 3838.63,672.55 3838.63,647.48 3847.97,647.48 3847.97,662.98
		3889.78,662.98 3889.78,613.48 3940.02,613.48 3940.02,624.01 3980.81,624.01 3980.81,598.95 3990.61,598.95 3990.61,613.48 	"
      />
      <polygon
        points="4114.01,621.2 4114.01,764.48 4205.49,764.48 4205.49,766.88 4296.08,766.88 4296.08,764.48
		4479.66,764.48 4479.66,630.31 4428.44,630.31 4428.44,621.2 4386.66,621.2 4386.66,630.31 4336.85,630.31 4336.85,621.2
		4296.08,621.2 4296.08,630.31 4245.94,630.31 4245.94,621.2 4205.49,621.2 4205.49,630.31 4155.48,630.31 4155.48,621.2 	"
      />
      <polygon
        points="4534.39,612.51 4534.39,799.46 4965.71,799.46 4965.71,611.18 4921.73,611.18 4921.73,597.13
		4912.85,597.13 4912.85,622.19 4871.83,622.19 4871.83,611.18 4819.66,611.18 4819.66,613.99 4779.56,613.99 4779.56,597.13
		4769.76,597.13 4769.76,622.65 4729.66,622.65 4729.66,611.18 4676.57,611.18 4676.57,615.13 4635.78,615.13 4635.78,597.13
		4625.99,597.13 4625.99,622.88 4585.88,622.88 4585.88,612.51 	"
      />
      <polygon
        points="5028.82,622.19 5028.82,765.05 5119.28,765.05 5119.28,768.01 5301.67,768.01 5301.67,765.05
		5394.75,765.05 5394.75,725.86 5423.68,725.86 5423.68,674.83 5394.52,674.83 5394.52,631.02 5342.34,631.02 5342.34,622.19
		5301.67,622.19 5301.67,631.02 5251.66,631.02 5251.66,622.19 5211.1,622.19 5211.1,631.02 5160.06,631.02 5160.06,622.19
		5119.28,622.19 5119.28,631.02 5069.61,631.02 5069.61,622.19 	"
      />
      <polygon
        points="5547.35,690.76 5584.11,727.52 5576.36,735.27 5604.29,763.2 5622.75,744.75 5630.13,752.13
		5619.06,763.2 5685.42,828.84 5677.68,836.59 5706.16,865.07 5724.16,846.16 5731.07,853.07 5720.36,863.78 5786.74,930.16
		5778.54,937.45 5806.87,965.78 5825.32,947.32 5832.61,954.61 5821.45,965.78 5852.36,995.78 5721.57,1126.56 5416.56,821.55 	"
      />
      <polygon
        points="5794.75,1144.52 5928.76,1144.52 5928.76,1195.41 5938.11,1195.41 5938.11,1236.04 5928.76,1236.04
		5928.76,1286.4 5938.11,1286.4 5938.11,1327.03 5951.83,1327.03 5951.83,1377.54 5961.04,1377.54 5961.04,1418.09 5951.83,1418.09
		5951.83,1468.52 5961.04,1468.52 5961.04,1509.23 5951.83,1509.23 5951.83,1559.66 5961.04,1559.66 5961.04,1600.29
		5974.49,1600.29 5974.49,1650.5 5984.13,1650.5 5984.13,1691.36 5974.49,1691.36 5974.49,1741.03 5984.13,1741.03 5984.13,1782.35
		5840.44,1782.35 5840.44,1691.36 5838,1691.36 5838,1600.29 5818.41,1600.29 5818.41,1509.23 5815.07,1509.23 5815.07,1418.09
		5819.17,1418.09 5819.17,1327.03 5792.74,1327.03 5792.74,1236.04 5794.75,1236.04 	"
      />
      <polygon
        points="5867.7,1843.33 5917.71,1843.33 5917.71,1872.38 5970.8,1872.38 5970.8,1914.65 5985.16,1914.65
		5985.16,1924.22 5959.75,1924.22 5959.75,1965.23 5971.49,1965.23 5971.49,2017.18 5968.52,2017.18 5968.52,2057.97
		5985.16,2057.97 5985.16,2068.22 5959.75,2068.22 5959.75,2108.09 5971.26,2108.09 5971.26,2159.59 5968.52,2159.59
		5968.52,2201.51 5985.16,2201.51 5985.16,2210.62 5959.75,2210.62 5959.75,2251.41 5971.26,2251.41 5971.26,2303.36
		5967.84,2303.36 5967.84,2345.51 5985.16,2345.51 5985.16,2354.85 5959.75,2354.85 5959.75,2394.73 5975.81,2394.73
		5975.81,2451.69 5917.26,2451.69 5917.26,2461.26 5906.09,2461.26 5906.09,2448.04 5785.11,2448.04 5785.11,1872.38
		5867.7,1872.38 	"
      />
      <polygon
        points="3902.6,1664.04 3902.6,1705.62 3932.64,1705.62 3932.64,1758.18 4011.19,1758.18 4011.19,1788.8
		4060.49,1788.8 4060.49,1758.18 4139.18,1758.18 4139.18,1705.62 4168.29,1705.62 4168.29,1664.04 4139.18,1664.04
		4139.18,1560.02 4168.29,1560.02 4168.29,1518.43 4139.18,1518.43 4139.18,1414.64 4168.29,1414.64 4168.29,1373.06
		4139.18,1373.06 4139.18,1320.27 3932.64,1320.27 3932.64,1373.06 3902.6,1373.06 3902.6,1414.64 3932.64,1414.64 3932.64,1518.43
		3902.6,1518.43 3902.6,1560.02 3932.64,1560.02 3932.64,1664.04 	"
      />
      <polygon
        points="4909.19,1321.27 4909.19,1353.15 4903.64,1353.15 4903.64,1413.22 4909.19,1413.22 4909.19,1442.79
		4903.64,1442.79 4903.64,1504.86 4909.19,1504.86 4909.19,1535.19 4903.64,1535.19 4903.64,1594.26 4909.19,1594.26
		4909.19,1625.59 4903.64,1625.59 4903.64,1685.65 4909.19,1685.65 4909.19,1717.25 4903.64,1717.25 4903.64,1777.32
		4909.19,1777.32 4977.57,1777.32 5070.44,1776.32 5070.44,1320.27 	"
      />
      <polygon
        points="4529.42,1321.5 4529.42,1353.88 4534.97,1353.88 4534.97,1413.95 4529.42,1413.95 4529.42,1443.52
		4534.97,1443.52 4534.97,1505.59 4529.42,1505.59 4529.42,1535.92 4534.97,1535.92 4534.97,1594.98 4529.42,1594.98
		4529.42,1626.32 4534.97,1626.32 4534.97,1686.38 4529.42,1686.38 4529.42,1717.98 4534.97,1717.98 4534.97,1777.8 4529.42,1777.8
		4461.04,1777.8 4368.17,1777.8 4368.17,1321.5 	"
      />
      <polygon
        points="3705.88,1420.73 3705.88,1329.59 3702.24,1329.59 3702.24,1237.09 3568.72,1237.09 3568.72,1289.03
		3560.06,1289.03 3560.06,1329.59 3568.72,1329.59 3568.72,1380.17 3560.06,1380.17 3560.06,1420.73 3568.72,1420.73
		3568.72,1471.31 3560.06,1471.31 3560.06,1511.87 3568.72,1511.87 3568.72,1562.45 3560.06,1562.45 3560.06,1603.01
		3568.72,1603.01 3568.72,1653.59 3560.06,1653.59 3560.06,1694.15 3568.72,1694.15 3568.72,1745.57 3560.06,1745.57
		3560.06,1786.13 3568.72,1786.13 3600.62,1786.13 3702.24,1786.13 3702.24,1694.15 3705.88,1694.15 3705.88,1603.01
		3702.24,1603.01 3702.24,1420.73 	"
      />
      <polygon
        points="4516.68,1108.66 4487.06,1108.66 4487.06,1040.77 4394.09,1040.77 4394.09,1017.53 3965.74,1017.53
		3965.74,1069.02 3915.16,1069.02 3874.14,1069.02 3874.14,1201.17 3874.14,1211.65 3915.16,1211.65 3915.16,1201.17
		3965.74,1201.17 3965.74,1205.27 4017.69,1205.27 4017.69,1201.17 4017.69,1190.23 4057.33,1190.23 4057.33,1217.8 4066.22,1217.8
		4066.22,1201.17 4108.14,1201.17 4108.14,1203.34 4159.87,1203.34 4159.87,1190.23 4201.11,1190.23 4201.11,1217.8 4210.45,1217.8
		4210.45,1201.17 4251.46,1201.17 4251.46,1203.34 4304.09,1203.34 4304.09,1190.23 4344.88,1190.23 4344.88,1201.17
		4344.88,1216.44 4353.54,1216.44 4353.54,1201.17 4394.09,1201.17 4394.09,1216.44 4403.44,1216.44 4403.44,1201.17
		4435.56,1201.17 4435.56,1207.55 4491.61,1207.55 4491.61,1157.88 4516.68,1157.88 	"
      />
      <polygon
        points="4923.16,1017.53 4923.16,1177.7 4923.16,1191.15 4923.16,1203.68 4975.79,1203.68 4975.79,1191.15
		5015.66,1191.15 5015.66,1216.89 5026.37,1216.89 5026.37,1200.26 5065.79,1200.26 5065.79,1203.68 5119.11,1203.68
		5119.11,1191.15 5159.89,1191.15 5159.89,1216.89 5168.55,1216.89 5168.55,1200.26 5209.79,1200.26 5209.79,1202.54
		5262.2,1202.54 5262.2,1191.15 5302.98,1191.15 5302.98,1215.98 5311.87,1215.98 5311.87,1201.63 5354.7,1201.63 5354.7,1191.15
		5354.7,1017.53 	"
      />
      <polygon
        points="5079.23,2126.81 5079.23,2028.99 5029.71,2028.99 5029.71,2020.18 4989.61,2020.18 4989.61,2040.32
		4959.53,2040.32 4959.53,2017.18 4927.18,2017.18 4927.18,2040.32 4897.25,2040.32 4897.25,2020.18 4856.24,2020.18
		4856.24,2028.99 4807.63,2028.99 4807.63,2126.81 4839.53,2126.81 4839.53,2176.64 4811.28,2176.64 4811.28,2217.65
		4839.53,2217.65 4839.53,2322.92 4811.28,2322.92 4811.28,2363.93 4839.53,2363.93 4839.53,2416.33 4941.43,2416.33
		4941.43,2488.94 5046.42,2488.94 5046.42,2437.29 5075.58,2437.29 5075.58,2416.33 5046.42,2416.33 5046.42,2363.93
		5075.58,2363.93 5075.58,2322.92 5046.42,2322.92 5046.42,2217.65 5075.58,2217.65 5075.58,2176.64 5046.42,2176.64
		5046.42,2126.81 	"
      />
      <polygon
        points="4306.15,2121.73 4306.15,2023.91 4355.67,2023.91 4355.67,2015.1 4395.77,2015.1 4395.77,2035.24
		4425.85,2035.24 4425.85,2012.1 4458.2,2012.1 4458.2,2035.24 4488.13,2035.24 4488.13,2015.1 4529.14,2015.1 4529.14,2023.91
		4577.75,2023.91 4577.75,2121.73 4545.85,2121.73 4545.85,2171.56 4574.1,2171.56 4574.1,2212.57 4545.85,2212.57 4545.85,2317.84
		4574.1,2317.84 4574.1,2358.85 4545.85,2358.85 4545.85,2411.25 4441.95,2411.25 4441.95,2483.86 4338.96,2483.86 4338.96,2432.22
		4309.8,2432.22 4309.8,2411.25 4338.96,2411.25 4338.96,2358.85 4309.8,2358.85 4309.8,2317.84 4338.96,2317.84 4338.96,2212.57
		4309.8,2212.57 4309.8,2171.56 4338.96,2171.56 4338.96,2121.73 	"
      />
      <polygon
        points="4056.89,2007.23 4056.89,2010.04 4047.4,2010.04 4047.4,2023.63 3952.56,2023.63 3952.56,2037.22
		3900.52,2037.22 3900.52,2129.76 3909.23,2129.76 3909.23,2166.41 3900.52,2166.41 3900.52,2348.16 3909.23,2348.16
		3909.23,2383.28 3900.52,2383.28 3900.52,2475.82 3952.56,2475.82 3952.56,2503.5 3961.27,2503.5 3961.27,2489.66 4056.89,2489.66
		4056.89,2475.82 4109.44,2475.82 4109.44,2383.28 4100.21,2383.28 4100.21,2348.16 4109.44,2348.16 4109.44,2166.41
		4100.21,2166.41 4100.21,2129.76 4109.44,2129.76 4109.44,2037.22 4109.44,2007.23 	"
      />
      <polygon
        points="3750.84,2130.54 3747.65,2130.54 3747.65,2039.41 3614.58,2039.41 3614.58,2090.9 3604.56,2090.9
		3604.56,2130.54 3614.58,2130.54 3614.58,2180.67 3604.56,2180.67 3604.56,2221.46 3614.58,2221.46 3614.58,2272.27
		3604.56,2272.27 3604.56,2312.37 3614.58,2312.37 3614.58,2363.41 3604.56,2363.41 3604.56,2404.42 3614.58,2404.42
		3712.1,2404.42 3747.65,2404.42 3747.65,2312.37 3750.84,2312.37 	"
      />
      <polygon
        points="4675.01,1076.1 4819.89,1076.1 4819.89,1173.83 4802.15,1173.83 4802.15,1285.26 4583.97,1285.26
		4583.97,1261.29 4574.32,1261.29 4574.32,1186.28 4583.66,1186.28 4583.66,1095.09 4675.01,1095.09 	"
      />
      <polygon
        points="5574.1,1320.64 5574.1,1292.89 5524.42,1292.89 5524.42,1320.64 5478.85,1320.64 5478.85,1371.56
		5465.64,1371.56 5465.64,1374.84 5471.56,1374.84 5471.56,1411.37 5478.85,1411.37 5478.85,1463.82 5465.64,1463.82
		5465.64,1467.1 5471.56,1467.1 5471.56,1503.63 5478.85,1503.63 5478.85,1554.09 5463.64,1554.09 5463.64,1557.37 5469.56,1557.37
		5469.56,1593.89 5476.85,1593.89 5476.85,1645.61 5461.64,1645.61 5461.64,1648.89 5467.56,1648.89 5467.56,1685.42
		5478.85,1685.42 5478.85,1737.51 5464.64,1737.51 5464.64,1740.79 5470.56,1740.79 5470.56,1777.32 5478.85,1777.32
		5519.32,1777.32 5611.92,1777.32 5611.92,1320.64 	"
      />
      <polygon
        points="5346.35,1320.64 5346.35,1371.56 5359.56,1371.56 5359.56,1374.84 5353.64,1374.84 5353.64,1411.37
		5346.35,1411.37 5346.35,1463.82 5359.56,1463.82 5359.56,1467.1 5353.64,1467.1 5353.64,1503.63 5346.35,1503.63 5346.35,1554.09
		5361.56,1554.09 5361.56,1557.37 5355.64,1557.37 5355.64,1593.89 5348.35,1593.89 5348.35,1645.61 5363.56,1645.61
		5363.56,1648.89 5357.64,1648.89 5357.64,1685.42 5346.35,1685.42 5346.35,1737.51 5360.56,1737.51 5360.56,1740.79
		5354.64,1740.79 5354.64,1777.32 5346.35,1777.32 5305.88,1777.32 5213.28,1777.32 5213.28,1320.64 	"
      />
      <polygon
        points="5517.59,2030.48 5517.59,2081.4 5504.37,2081.4 5504.37,2084.68 5510.3,2084.68 5510.3,2121.21
		5517.59,2121.21 5517.59,2173.66 5504.37,2173.66 5504.37,2176.94 5510.3,2176.94 5510.3,2213.47 5517.59,2213.47 5517.59,2263.93
		5502.37,2263.93 5502.37,2267.21 5508.3,2267.21 5508.3,2303.73 5515.59,2303.73 5515.59,2355.45 5500.37,2355.45 5500.37,2358.73
		5506.3,2358.73 5506.3,2395.26 5517.59,2395.26 5517.59,2447.35 5503.37,2447.35 5503.37,2450.63 5509.3,2450.63 5509.3,2487.16
		5517.59,2487.16 5558.06,2487.16 5650.65,2487.16 5650.65,2030.48 	"
      />
      <polygon
        points="5385.08,2030.48 5385.08,2081.4 5398.3,2081.4 5398.3,2084.68 5392.37,2084.68 5392.37,2121.21
		5385.08,2121.21 5385.08,2173.66 5398.3,2173.66 5398.3,2176.94 5392.37,2176.94 5392.37,2213.47 5385.08,2213.47 5385.08,2263.93
		5400.3,2263.93 5400.3,2267.21 5394.37,2267.21 5394.37,2303.73 5387.08,2303.73 5387.08,2355.45 5402.3,2355.45 5402.3,2358.73
		5396.37,2358.73 5396.37,2395.26 5385.08,2395.26 5385.08,2447.35 5399.3,2447.35 5399.3,2450.63 5393.37,2450.63 5393.37,2487.16
		5385.08,2487.16 5344.61,2487.16 5252.02,2487.16 5252.02,2030.48 	"
      />
    </g>
  );
};

export default MyComponent;
