import { useDispatch, useSelector } from "react-redux";
import S from "./PageCodonminiumKeyplan.module.sass";
import {
  selectCondoBuilding,
  updateCondoBuilding,
  updatePage,
} from "../../store/condominiumsSlice";
import R from "../../constants/routes";
import { useEffect } from "@wordpress/element";
import { T__Plan_Name } from "../../constants/floorplans/interface";
import { T__Buildings_Title } from "../../constants/buildings";
import BuildingChanger from "../Components/BuildingChanger";
import Building6 from "./Building6";
// import CondoBuildingSwitch from "../../components/CondoBuildingSwitch";
const Page_Condominium_Key_Plan = () => {
  const dispatch = useDispatch();
  const condoBuilding = useSelector(selectCondoBuilding);

  const currentCondoBuilding = useSelector(selectCondoBuilding);

  // console.log("currentCondoBuilding=", currentCondoBuilding);

  useEffect(() => {
    dispatch(updatePage({ page: R["CONDOMINIUM-KEY-PLAN"] }));
  }, []);

  const click = (plan: T__Plan_Name) => {
    const building = condoBuilding.replace(/\s/g, "").toLowerCase();

    // @ts-ignore
    const lowercasePlan = plan.toLowerCase();
    const url = `https://highstreetvillage.ca/condos/${building}-${lowercasePlan}`;
    window.open(url, "_self");
  };

  const changeBuilding = (building: T__Buildings_Title) => {
    dispatch(updateCondoBuilding({ condoBuilding: building }));
  };

  // @ts-ignore
  const buildings: { [key in T__Buildings_Title]: React.ReactNode } = {
    // "Building 3": <Building3 click={click} />,
    // "Building 4": <Building4 click={click} />,
    // "Building 5": <Building5 click={click} />,
    "Building 6": <Building6 click={click} />,
  };
  return (
    <div className={S.PageCondominiumKeyplan}>
      <BuildingChanger />
      <main>
        {/*<CondoBuildingSwitch />*/}
        {currentCondoBuilding !== undefined
          ? // @ts-ignore
            buildings[currentCondoBuilding]
          : null}
      </main>
    </div>
  );
};

// @ts-ignore
export default Page_Condominium_Key_Plan;
