import {
  TransformComponent,
  TransformWrapper,
} from "@pronestor/react-zoom-pan-pinch";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import S from "./PageSitemap.module.sass";
import Sitemap from "./Sitemap";
import { useEffect } from "@wordpress/element";
import { useDispatch } from "react-redux";
import { updatePage } from "../../store/condominiumsSlice";
import R from "../../constants/routes";
// import useWindowSize from "../../hooks/useWindowSize";
// import { useState } from "react";

// const ScreenSizeDetector = require("screen-size-detector");

function PageSitemap() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updatePage({ page: R.SITEMAP }));
  }, []);

  return (
    <TransformWrapper
      wheel={{ disabled: true, step: 0.05 }}
      panning={{ disabled: false }}
      doubleClick={{ animationTime: 1000 }}
      zoomAnimation={{ animationTime: 1000 }}
      initialScale={2.25}
      initialPositionX={0}
      // initialPositionY={0}
      // initialPositionX={window.innerWidth * -0.4}
      initialPositionY={window.innerHeight * -0.22265441751368253}
      // onInit={(rr) => {
      //   const x = 0;
      //   const rate = -569.55 / window.innerHeight;
      //   console.log("rate=", rate);
      //   // rr.setTransform(x, y, 2.25);
      // }}
    >
      {
        // @ts-ignore
        ({ zoomIn, zoomOut, setTransform, resetTransform, ...rest }) => {
          return (
            <div className={S.PageSitemap}>
              <div className={S.SitemapContainer}>
                <div className={S.RatioContainer}>
                  <main>
                    <TransformComponent>
                      <Sitemap setTransform={setTransform} />
                    </TransformComponent>
                  </main>
                </div>
                <div className={S.Bottom}>
                  <ButtonGroup variant="outlined" disableElevation>
                    <Button onClick={() => zoomIn()}>Zoom In</Button>
                    <Button onClick={() => zoomOut()}>Zoom Out</Button>
                    <Button onClick={() => resetTransform()}>Reset</Button>
                  </ButtonGroup>
                </div>
              </div>
            </div>
          );
        }
      }
    </TransformWrapper>
  );
}

// @ts-ignore
export default PageSitemap;
