import { I__Plan } from "../interface";

const Building4: Array<I__Plan> = [
  {
    title: "A",
    type: "condominiums",
    condoBuilding: "Building 5",
    bedroom: "studio",
    bath: 1,
    area: [499],
  },
  {
    title: "B",
    type: "condominiums",
    condoBuilding: "Building 5",
    bedroom: 1,
    bath: 1,
    area: [581, 585],
  },
  {
    title: "C",
    type: "condominiums",
    condoBuilding: "Building 5",
    bedroom: 1,
    bath: 1,
    additional: "den",
    area: [592],
  },
  {
    title: "C1",
    type: "condominiums",
    condoBuilding: "Building 5",
    bedroom: 1,
    bath: 1,
    additional: "den",
    area: [630],
  },
  {
    title: "D",
    type: "condominiums",
    condoBuilding: "Building 5",
    bedroom: 2,
    bath: 2,
    area: [719],
  },
  {
    title: "E",
    type: "condominiums",
    condoBuilding: "Building 5",
    bedroom: 2,
    bath: 2,
    area: [767],
  },
  {
    title: "F",
    type: "condominiums",
    condoBuilding: "Building 5",
    bedroom: 2,
    bath: 2,
    area: [798],
  },
  {
    title: "G",
    type: "condominiums",
    condoBuilding: "Building 5",
    bedroom: 2,
    bath: 2,
    area: [798],
  },
  {
    title: "H",
    type: "condominiums",
    condoBuilding: "Building 5",
    bedroom: 2,
    bath: 2,
    additional: "den",
    area: [781],
  },
];

export default Building4;
