import _ from "lodash";
import S from "../SVG.module.sass";

import BUILDINGS, { I__Buildings } from "../../../../constants/buildings";
import { T__Plan_Name } from "../../../../constants/floorplans/interface";

type Props = {
  unitClass: (build: I__Buildings | undefined, unit: string) => string;
  click: (planName: T__Plan_Name) => void;
};

const Building19 = ({ unitClass }: Props) => {
  const b = _.find(BUILDINGS, { title: "Building 19" });
  return (
    <g id="BUILDING-19">
      <g className={unitClass(b, "76")} id="UNIT-76-B1">
        <polygon
          className={S.Hot}
          points="4909.61,1321.64 4909.61,1353.52 4904.07,1353.52 4904.07,1413.59 4909.61,1413.59 5070.87,1413.59
				5070.87,1320.64 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4970.9678 1363.9092)"
            className={S.Number}
          >
            76
          </text>
          <text
            transform="matrix(1 0 0 1 4973.3711 1393.2885)"
            className={S.Plan}
          >
            B1
          </text>
        </g>
      </g>
      <g className={unitClass(b, "77")} id="UNIT-77-B">
        <polygon
          className={S.Hot}
          points="4909.61,1443.16 4904.07,1443.16 4904.07,1505.23 4909.61,1505.23 5070.87,1505.23 5070.87,1413.59
				4909.61,1413.59 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4971.5776 1454.3484)"
            className={S.Number}
          >
            77
          </text>
          <text
            transform="matrix(1 0 0 1 4978.0249 1483.7273)"
            className={S.Plan}
          >
            B
          </text>
        </g>
      </g>
      <g className={unitClass(b, "78")} id="UNIT-78-B">
        <polygon
          className={S.Hot}
          points="4909.61,1535.56 4904.07,1535.56 4904.07,1594.63 4909.61,1594.63 5070.87,1594.63 5070.87,1505.23
				4909.61,1505.23 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4971.3062 1544.7874)"
            className={S.Number}
          >
            78
          </text>
          <text
            transform="matrix(1 0 0 1 4978.0254 1574.1663)"
            className={S.Plan}
          >
            B
          </text>
        </g>
      </g>
      <g className={unitClass(b, "79")} id="UNIT-79-B">
        <polygon
          className={S.Hot}
          points="4909.61,1625.96 4904.07,1625.96 4904.07,1686.02 4909.61,1686.02 5070.87,1686.02 5070.87,1594.63
				4909.61,1594.63 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4970.9678 1635.2263)"
            className={S.Number}
          >
            79
          </text>
          <text
            transform="matrix(1 0 0 1 4978.0254 1664.6055)"
            className={S.Plan}
          >
            B
          </text>
        </g>
      </g>
      <g className={unitClass(b, "80")} id="UNIT-80-B2">
        <polygon
          className={S.Hot}
          points="4909.61,1717.62 4904.07,1717.62 4904.07,1777.69 4909.61,1777.69 4978,1777.69 5070.87,1776.69
				5070.87,1686.02 4909.61,1686.02 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4969.8105 1725.6655)"
            className={S.Number}
          >
            80
          </text>
          <text
            transform="matrix(1 0 0 1 4970.2383 1755.0444)"
            className={S.Plan}
          >
            B2
          </text>
        </g>
      </g>
    </g>
  );
};

export default Building19;
