import _ from "lodash";
import S from "../SVG.module.sass";

import BUILDINGS, { I__Buildings } from "../../../../constants/buildings";
import { T__Plan_Name } from "../../../../constants/floorplans/interface";

type Props = {
  unitClass: (build: I__Buildings | undefined, unit: string) => string;
  click: (planName: T__Plan_Name) => void;
};

const Building16 = ({ unitClass }: Props) => {
  const b = _.find(BUILDINGS, { title: "Building 16" });
  return (
    <g id="BUILDING-16">
      <g className={unitClass(b, "132")} id="UNIT-132-A4">
        <polygon
          className={S.Hot}
          points="3568.72,1745.57 3560.06,1745.57 3560.06,1786.13 3568.72,1786.13 3600.62,1786.13 3702.24,1786.13
				3702.24,1694.15 3568.72,1694.15 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3617.1001 1734.9764)"
            className={S.Number}
          >
            132
          </text>
          <text
            transform="matrix(1 0 0 1 3619.2561 1764.3556)"
            className={S.Plan}
          >
            A4
          </text>
        </g>
      </g>
      <g className={unitClass(b, "133")} id="UNIT-133-Aa">
        <polygon
          className={S.Hot}
          points="3568.72,1653.59 3560.06,1653.59 3560.06,1694.15 3568.72,1694.15 3702.24,1694.15 3705.88,1694.15
				3705.88,1603.01 3702.24,1603.01 3568.72,1603.01 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3617.0342 1644.0033)"
            className={S.Number}
          >
            133
          </text>
          <text
            transform="matrix(1 0 0 1 3620.4519 1673.3824)"
            className={S.Plan}
          >
            Aa
          </text>
        </g>
      </g>
      <g className={unitClass(b, "134")} id="UNIT-134-A">
        <polygon
          className={S.Hot}
          points="3568.72,1511.87 3568.72,1562.45 3560.06,1562.45 3560.06,1603.01 3568.72,1603.01 3702.24,1603.01
				3702.24,1511.87 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3616.2029 1553.0297)"
            className={S.Number}
          >
            134
          </text>
          <text
            transform="matrix(1 0 0 1 3627.98 1582.4086)"
            className={S.Plan}
          >
            A
          </text>
        </g>
      </g>
      <g className={unitClass(b, "135")} id="UNIT-135-A">
        <polygon
          className={S.Hot}
          points="3702.24,1420.73 3568.72,1420.73 3568.72,1471.31 3560.06,1471.31 3560.06,1511.87 3568.72,1511.87
				3702.24,1511.87 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3616.969 1462.0568)"
            className={S.Number}
          >
            135
          </text>
          <text
            transform="matrix(1 0 0 1 3627.9785 1491.4357)"
            className={S.Plan}
          >
            A
          </text>
        </g>
      </g>
      <g className={unitClass(b, "136")} id="UNIT-136-Aa">
        <polygon
          className={S.Hot}
          points="3705.88,1420.73 3705.88,1329.59 3702.24,1329.59 3568.72,1329.59 3568.72,1380.17 3560.06,1380.17
				3560.06,1420.73 3568.72,1420.73 3702.24,1420.73 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3616.5398 1371.0836)"
            className={S.Number}
          >
            136
          </text>
          <text
            transform="matrix(1 0 0 1 3620.4517 1400.4628)"
            className={S.Plan}
          >
            Aa
          </text>
        </g>
      </g>
      <g className={unitClass(b, "137")} id="UNIT-137-A2">
        <polygon
          className={S.Hot}
          points="3702.24,1237.09 3568.72,1237.09 3568.72,1289.03 3560.06,1289.03 3560.06,1329.59 3568.72,1329.59
				3702.24,1329.59 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3617.1516 1280.1107)"
            className={S.Number}
          >
            137
          </text>
          <text
            transform="matrix(1 0 0 1 3620.1924 1309.4899)"
            className={S.Plan}
          >
            A2
          </text>
        </g>
      </g>
    </g>
  );
};

export default Building16;
