import _ from "lodash";
import S from "../SVG.module.sass";

import BUILDINGS, { I__Buildings } from "../../../../constants/buildings";
import { T__Plan_Name } from "../../../../constants/floorplans/interface";

type Props = {
  unitClass: (build: I__Buildings | undefined, unit: string) => string;
  click: (planName: T__Plan_Name) => void;
};

const Building17 = ({ unitClass }: Props) => {
  const b = _.find(BUILDINGS, { title: "Building 17" });
  return (
    <g id="BUILDING-17">
      <g className={unitClass(b, "108")} id="UNIT-108-D1">
        <polygon
          className={S.Hot}
          points="4139.18,1372.79 4139.18,1320 4033.99,1320 4033.99,1393.58 4168.29,1393.58 4168.29,1372.79 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4065.8669 1353.9558)"
            className={S.Number}
          >
            108
          </text>
          <text
            transform="matrix(1 0 0 1 4073.7964 1383.3347)"
            className={S.Plan}
          >
            D1
          </text>
        </g>
      </g>
      <g className={unitClass(b, "109")} id="UNIT-109-D">
        <polygon
          className={S.Hot}
          points="4033.99,1466.93 4139.18,1466.93 4139.18,1414.37 4168.29,1414.37 4168.29,1393.58 4033.99,1393.58
							"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4065.5281 1426.4302)"
            className={S.Number}
          >
            109
          </text>
          <text
            transform="matrix(1 0 0 1 4078.4487 1455.8093)"
            className={S.Plan}
          >
            D
          </text>
        </g>
      </g>
      <g className={unitClass(b, "110")} id="UNIT-110-D">
        <polygon
          className={S.Hot}
          points="4033.99,1539.48 4168.29,1539.48 4168.29,1518.16 4139.18,1518.16 4139.18,1466.93 4033.99,1466.93
							"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4068.9077 1498.6577)"
            className={S.Number}
          >
            110
          </text>
          <text
            transform="matrix(1 0 0 1 4078.4485 1528.0369)"
            className={S.Plan}
          >
            D
          </text>
        </g>
      </g>
      <g className={unitClass(b, "111")} id="UNIT-111-D">
        <polygon
          className={S.Hot}
          points="4033.99,1611.56 4139.18,1611.56 4139.18,1559.75 4168.29,1559.75 4168.29,1539.48 4033.99,1539.48
							"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4073.1738 1571.009)"
            className={S.Number}
          >
            111
          </text>
          <text
            transform="matrix(1 0 0 1 4078.45 1600.3882)"
            className={S.Plan}
          >
            D
          </text>
        </g>
      </g>
      <g className={unitClass(b, "112")} id="UNIT-112-D">
        <polygon
          className={S.Hot}
          points="4033.99,1683.64 4168.29,1683.64 4168.29,1663.77 4139.18,1663.77 4139.18,1611.56 4033.99,1611.56
							"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4070.3518 1643.3608)"
            className={S.Number}
          >
            112
          </text>
          <text
            transform="matrix(1 0 0 1 4078.4492 1672.7397)"
            className={S.Plan}
          >
            D
          </text>
        </g>
      </g>
      <g className={unitClass(b, "113")} id="UNIT-113-D1">
        <polygon
          className={S.Hot}
          points="4033.99,1757.91 4139.18,1757.91 4139.18,1705.35 4168.29,1705.35 4168.29,1683.64 4033.99,1683.64
							"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4070.2869 1715.7117)"
            className={S.Number}
          >
            113
          </text>
          <text
            transform="matrix(1 0 0 1 4073.7964 1745.0906)"
            className={S.Plan}
          >
            D1
          </text>
        </g>
      </g>
      <g className={unitClass(b, "114")} id="UNIT-114-D1">
        <polygon
          className={S.Hot}
          points="4033.99,1757.91 4033.99,1683.64 3902.6,1683.64 3902.6,1705.35 3932.64,1705.35 3932.64,1757.91
							"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3963.8953 1715.7117)"
            className={S.Number}
          >
            114
          </text>
          <text
            transform="matrix(1 0 0 1 3968.2368 1745.0906)"
            className={S.Plan}
          >
            D1
          </text>
        </g>
      </g>
      <g className={unitClass(b, "115")} id="UNIT-115-D">
        <polygon
          className={S.Hot}
          points="4033.99,1611.56 3932.64,1611.56 3932.64,1663.77 3902.6,1663.77 3902.6,1683.64 4033.99,1683.64
							"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3964.6624 1643.3608)"
            className={S.Number}
          >
            115
          </text>
          <text
            transform="matrix(1 0 0 1 3972.8896 1672.7397)"
            className={S.Plan}
          >
            D
          </text>
        </g>
      </g>
      <g className={unitClass(b, "116")} id="UNIT-116-D">
        <polygon
          className={S.Hot}
          points="4033.99,1539.48 3902.6,1539.48 3902.6,1559.75 3932.64,1559.75 3932.64,1611.56 4033.99,1611.56
							"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3964.2334 1571.009)"
            className={S.Number}
          >
            116
          </text>
          <text
            transform="matrix(1 0 0 1 3972.8904 1600.3882)"
            className={S.Plan}
          >
            D
          </text>
        </g>
      </g>
      <g className={unitClass(b, "117")} id="UNIT-117-D">
        <polygon
          className={S.Hot}
          points="4033.99,1466.93 3932.64,1466.93 3932.64,1518.16 3902.6,1518.16 3902.6,1539.48 4033.99,1539.48
							"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3964.8433 1498.6577)"
            className={S.Number}
          >
            117
          </text>
          <text
            transform="matrix(1 0 0 1 3972.8889 1528.0369)"
            className={S.Plan}
          >
            D
          </text>
        </g>
      </g>
      <g className={unitClass(b, "118")} id="UNIT-118-D">
        <polygon
          className={S.Hot}
          points="4033.99,1393.58 3902.6,1393.58 3902.6,1414.37 3932.64,1414.37 3932.64,1466.93 4033.99,1466.93
							"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3964.571 1426.4302)"
            className={S.Number}
          >
            118
          </text>
          <text
            transform="matrix(1 0 0 1 3972.8892 1455.8093)"
            className={S.Plan}
          >
            D
          </text>
        </g>
      </g>
      <g className={unitClass(b, "119")} id="UNIT-119-D1">
        <polygon
          className={S.Hot}
          points="4033.99,1320 3932.64,1320 3932.64,1372.79 3902.6,1372.79 3902.6,1393.58 4033.99,1393.58 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3964.2332 1353.9558)"
            className={S.Number}
          >
            119
          </text>
          <text
            transform="matrix(1 0 0 1 3968.2368 1383.3347)"
            className={S.Plan}
          >
            D1
          </text>
        </g>
      </g>
    </g>
  );
};

export default Building17;
