import { useEffect } from "@wordpress/element";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store";
import R from "../../constants/routes";
import Application from "../__Application";
import PageSitemap from "../Page_Sitemap";
import { fetchUnits } from "../../store/condominiumsSlice";
import SectionNavigation from "../SectionNavigation";
import Page_Condominium_Plans from "../Page_Condominium_Plans";
import Page_Condominium_Key_Plan from "../Page_Condominium_Key_Plan";

const RoutesContainer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  /** *****************
   * Load first page
   ****************** */
  useEffect(() => {
    navigate(R.SITEMAP.pathname);
    dispatch(fetchUnits());
  }, []);

  return (
    <Application>
      <SectionNavigation />
      <Routes location={location} key={location.key}>
        <Route path={R.SITEMAP.pathname} element={<PageSitemap />} />
        <Route
          path={R["CONDOMINIUM-PLAN-TYPE"].pathname}
          element={<Page_Condominium_Plans />}
        />
        <Route
          path={R["CONDOMINIUM-KEY-PLAN"].pathname}
          element={<Page_Condominium_Key_Plan />}
        />
      </Routes>
    </Application>
  );
};

export default RoutesContainer;
