import _ from "lodash";
import S from "../SVG.module.sass";

import BUILDINGS, { I__Buildings } from "../../../../constants/buildings";
import { T__Plan_Name } from "../../../../constants/floorplans/interface";

type Props = {
  unitClass: (build: I__Buildings | undefined, unit: string) => string;
  click: (planName: T__Plan_Name) => void;
};

const Building12 = ({ unitClass }: Props) => {
  const b = _.find(BUILDINGS, { title: "Building 12" });
  return (
    <g id="BUILDING-12">
      <g className={unitClass(b, "128")} id="UNIT-128-A4">
        <polygon
          className={S.Hot}
          points="3614.58,2363.41 3604.56,2363.41 3604.56,2404.42 3614.58,2404.42 3712.1,2404.42 3747.65,2404.42
				3747.65,2312.37 3614.58,2312.37 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3660.3679 2351.3574)"
            className={S.Number}
          >
            128
          </text>
          <text
            transform="matrix(1 0 0 1 3662.6802 2380.7363)"
            className={S.Plan}
          >
            A4
          </text>
        </g>
      </g>
      <g className={unitClass(b, "129")} id="UNIT-129-Aa">
        <polygon
          className={S.Hot}
          points="3614.58,2272.27 3604.56,2272.27 3604.56,2312.37 3614.58,2312.37 3747.65,2312.37 3750.84,2312.37
				3750.84,2221.46 3614.58,2221.46 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3660.0312 2261.2598)"
            className={S.Number}
          >
            129
          </text>
          <text
            transform="matrix(1 0 0 1 3663.8777 2290.6387)"
            className={S.Plan}
          >
            Aa
          </text>
        </g>
      </g>
      <g className={unitClass(b, "130")} id="UNIT-130-Aa">
        <polygon
          className={S.Hot}
          points="3747.65,2130.54 3614.58,2130.54 3614.58,2180.67 3604.56,2180.67 3604.56,2221.46 3614.58,2221.46
				3750.84,2221.46 3750.84,2130.54 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3659.0815 2171.1631)"
            className={S.Number}
          >
            130
          </text>
          <text
            transform="matrix(1 0 0 1 3663.8772 2200.542)"
            className={S.Plan}
          >
            Aa
          </text>
        </g>
      </g>
      <g className={unitClass(b, "131")} id="UNIT-131-A2">
        <polygon
          className={S.Hot}
          points="3747.65,2039.41 3614.58,2039.41 3614.58,2090.9 3604.56,2090.9 3604.56,2130.54 3614.58,2130.54
				3747.65,2130.54 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 3663.345 2081.0669)"
            className={S.Number}
          >
            131
          </text>
          <text
            transform="matrix(1 0 0 1 3663.6172 2110.4458)"
            className={S.Plan}
          >
            A2
          </text>
        </g>
      </g>
    </g>
  );
};

export default Building12;
