import S from "./PageCondominiumPlans.module.sass";
import FLOORPLANS from "../../constants/floorplans";
import Box from "../Components/UnitBox";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "@wordpress/element";
import {
  selectCondoBuilding,
  selectUnits,
  updatePage,
} from "../../store/condominiumsSlice";
import R from "../../constants/routes";
import { I__Plan } from "../../constants/floorplans/interface";
import { I__Unit } from "../../store/I__InitialState";
import BuildingChanger from "../Components/BuildingChanger";
// import CondoBuildingSwitch from "../../components/CondoBuildingSwitch";

const Page_Condominium_Plans = () => {
  const UNITS: Array<I__Unit> | undefined = useSelector(selectUnits);
  const condoBuilding = useSelector(selectCondoBuilding);
  const CondominiumFloorplans = _.filter(FLOORPLANS, {
    type: "condominiums",
    condoBuilding,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updatePage({ page: R["CONDOMINIUM-PLAN-TYPE"] }));
  }, []);

  const click = (plan: I__Plan) => {
    const building = condoBuilding.replace(/\s/g, "").toLowerCase();
    // @ts-ignore
    const lowercasePlan = plan.title.toLowerCase();
    const url = `https://highstreetvillage.ca/condos/${building}-${lowercasePlan}`;
    window.open(url, "_self");
  };

  return (
    <div className={S.PageCondominiumPlans}>
      <BuildingChanger />
      <main>
        {/*<CondoBuildingSwitch />*/}
        <div className={S.Grid}>
          {CondominiumFloorplans.map((plan, index) => {
            const availableCondosUnits = _.filter(UNITS, (u) => {
              const show = u.status === "available" || u.status === "hold";

              return (
                u["building type"] === "Condominiums" &&
                u["building status"] === "available" &&
                u.floorplan === plan.title &&
                show
              );
            });

            const total = _.size(availableCondosUnits);
            // console.log(
            //   plan,
            //   plan.title,
            //   "availableCondosUnits =",
            //   availableCondosUnits,
            //   total
            // );
            return total > 0 ? (
              <Box click={click} plan={plan} key={index} />
            ) : null;
          })}
        </div>
      </main>
    </div>
  );
};

export default Page_Condominium_Plans;
