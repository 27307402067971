import S from "./UnitBox.module.sass";
import classNames from "classnames";
import numberToStringFormat from "../../../functions/numberToStringFormat";
import { I__Plan } from "../../../constants/floorplans/interface";

type Props = {
  plan: I__Plan;
  click: (plan: I__Plan) => void;
};

const Box = ({ plan, click }: Props) => {
  const boxClasses = () => {
    const classes = [
      S.Box,
      {
        [`${S.Sold}`]: plan.sold,
      },
    ];
    return classNames(classes);
  };

  return (
    <div className={boxClasses()} onClick={() => click(plan)}>
      <h1>
        {plan.title}
        <small>{plan.bedroom === "studio" && "*"}</small>
      </h1>
      <div className={S.Description}>
        {`${plan.bedroom} Bedroom${
          plan.additional !== undefined ? ` + ${plan.additional}` : ""
        }`}
        <br />
        {`${plan.bath} Bath`}
        <br />
        {plan.area.length > 1
          ? `${numberToStringFormat(plan.area[0])} - ${numberToStringFormat(
              plan.area[1]
            )} sq. ft.`
          : `${numberToStringFormat(plan.area[0])} sq. ft.`}
      </div>
    </div>
  );
};

export default Box;
