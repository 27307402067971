import _ from "lodash";
import S from "../SVG.module.sass";

import BUILDINGS, { I__Buildings } from "../../../../constants/buildings";
import { T__Plan_Name } from "../../../../constants/floorplans/interface";

type Props = {
  unitClass: (build: I__Buildings | undefined, unit: string) => string;
  click: (planName: T__Plan_Name) => void;
};

const Building9 = ({ unitClass }: Props) => {
  const b = _.find(BUILDINGS, { title: "Building 9" });
  return (
    <g id="BUILDING-9">
      <rect
        id="BUILDING-9-PATCH"
        x="4927.18"
        y="2017.18"
        className={S.Patch}
        width="32.35"
        height="46.75"
      />
      <g className={unitClass(b, "81")} id="UNIT-81-E">
        <polygon
          className={S.Hot}
          points="4954.97,2063.93 4941.43,2063.93 4941.43,2126.81 5046.42,2126.81 5079.23,2126.81 5079.23,2028.99
				5029.71,2028.99 5029.71,2020.18 4989.61,2020.18 4989.61,2040.32 4959.53,2040.32 4954.97,2040.32 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4983.0186 2084.9578)"
            className={S.Number}
          >
            81
          </text>
          <text
            transform="matrix(1 0 0 1 4987.645 2114.3367)"
            className={S.Plan}
          >
            E
          </text>
        </g>
      </g>
      <g className={unitClass(b, "82")} id="UNIT-82-D">
        <polygon
          className={S.Hot}
          points="4941.43,2126.81 4941.43,2198.21 5075.58,2198.21 5075.58,2176.64 5046.42,2176.64 5046.42,2126.81
							"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4980.1958 2157.4319)"
            className={S.Number}
          >
            82
          </text>
          <text
            transform="matrix(1 0 0 1 4986.1875 2186.8113)"
            className={S.Plan}
          >
            D
          </text>
        </g>
      </g>
      <g className={unitClass(b, "83")} id="UNIT-83-D">
        <polygon
          className={S.Hot}
          points="4941.43,2270.81 5046.42,2270.81 5046.42,2217.65 5075.58,2217.65 5075.58,2198.21 4941.43,2198.21
							"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4980.1313 2229.6594)"
            className={S.Number}
          >
            83
          </text>
          <text
            transform="matrix(1 0 0 1 4986.1875 2259.0388)"
            className={S.Plan}
          >
            D
          </text>
        </g>
      </g>
      <g className={unitClass(b, "84")} id="UNIT-84-D">
        <polygon
          className={S.Hot}
          points="4941.43,2343.42 5075.58,2343.42 5075.58,2322.92 5046.42,2322.92 5046.42,2270.81 4941.43,2270.81
							"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4979.3008 2302.011)"
            className={S.Number}
          >
            84
          </text>
          <text
            transform="matrix(1 0 0 1 4986.189 2331.3899)"
            className={S.Plan}
          >
            D
          </text>
        </g>
      </g>
      <g className={unitClass(b, "85")} id="UNIT-85-D">
        <polygon
          className={S.Hot}
          points="4941.43,2416.33 5046.42,2416.33 5046.42,2363.93 5075.58,2363.93 5075.58,2343.42 4941.43,2343.42
							"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4980.0664 2374.3625)"
            className={S.Number}
          >
            85
          </text>
          <text
            transform="matrix(1 0 0 1 4986.1885 2403.7415)"
            className={S.Plan}
          >
            D
          </text>
        </g>
      </g>
      <g className={unitClass(b, "86")} id="UNIT-86-D2">
        <polygon
          className={S.Hot}
          points="4941.43,2488.94 5046.42,2488.94 5046.42,2437.29 5075.58,2437.29 5075.58,2416.33 5046.42,2416.33
				4941.43,2416.33 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4979.6387 2446.7136)"
            className={S.Number}
          >
            86
          </text>
          <text
            transform="matrix(1 0 0 1 4978.4019 2476.0925)"
            className={S.Plan}
          >
            D2
          </text>
        </g>
      </g>
      <g className={unitClass(b, "87")} id="UNIT-87-D1">
        <polygon
          className={S.Hot}
          points="4811.28,2343.42 4811.28,2363.93 4839.53,2363.93 4839.53,2416.33 4941.43,2416.33 4941.43,2343.42
							"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4875.9541 2374.3625)"
            className={S.Number}
          >
            87
          </text>
          <text
            transform="matrix(1 0 0 1 4877.2402 2403.7415)"
            className={S.Plan}
          >
            D1
          </text>
        </g>
      </g>
      <g className={unitClass(b, "88")} id="UNIT-88-D">
        <polygon
          className={S.Hot}
          points="4941.43,2270.81 4839.53,2270.81 4839.53,2322.92 4811.28,2322.92 4811.28,2343.42 4941.43,2343.42
							"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4875.6807 2302.011)"
            className={S.Number}
          >
            88
          </text>
          <text
            transform="matrix(1 0 0 1 4881.8936 2331.3899)"
            className={S.Plan}
          >
            D
          </text>
        </g>
      </g>
      <g className={unitClass(b, "89")} id="UNIT-89-D">
        <polygon
          className={S.Hot}
          points="4941.43,2198.21 4811.28,2198.21 4811.28,2217.65 4839.53,2217.65 4839.53,2270.81 4941.43,2270.81
							"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4875.3428 2229.6594)"
            className={S.Number}
          >
            89
          </text>
          <text
            transform="matrix(1 0 0 1 4881.8936 2259.0383)"
            className={S.Plan}
          >
            D
          </text>
        </g>
      </g>
      <g className={unitClass(b, "90")} id="UNIT-90-D">
        <polygon
          className={S.Hot}
          points="4941.43,2126.81 4839.53,2126.81 4839.53,2176.64 4811.28,2176.64 4811.28,2198.21 4941.43,2198.21
							"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4874.1206 2157.4319)"
            className={S.Number}
          >
            90
          </text>
          <text
            transform="matrix(1 0 0 1 4881.8926 2186.8113)"
            className={S.Plan}
          >
            D
          </text>
        </g>
      </g>
      <g className={unitClass(b, "91")} id="UNIT-91-E">
        <polygon
          className={S.Hot}
          points="4941.43,2063.93 4927.18,2063.93 4927.18,2040.32 4897.25,2040.32 4897.25,2020.18 4856.24,2020.18
				4856.24,2028.99 4807.63,2028.99 4807.63,2126.81 4839.53,2126.81 4941.43,2126.81 			"
        />
        <g className={S.UnitLabel}>
          <text
            transform="matrix(1 0 0 1 4878.3848 2084.9578)"
            className={S.Number}
          >
            91
          </text>
          <text
            transform="matrix(1 0 0 1 4883.3496 2114.3367)"
            className={S.Plan}
          >
            E
          </text>
        </g>
      </g>
    </g>
  );
};

export default Building9;
